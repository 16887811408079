import type { AccessKey } from "@chainsafe/files-api-client"
import { t } from "@lingui/macro"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useState } from "react"

import { useStorageApi } from "../../../../contexts/StorageContext"

export const API_KEY_TYPE = {
  GAMING: "gaming",
  STORAGE: "storage",
  S3: "s3",
} as const

export type UseApiKeysReturn = {
  APIKeys: AccessKey[] | undefined
  generatedAPIKey?: AccessKey
  generateAPIKey: () => Promise<AccessKey | undefined>
  isDeleteAPIKeyLoading: boolean
  deleteAPIKey: (keyId: string) => Promise<void>
  clearGeneratedAPIKey: () => void
}

export const useApiKeys = (): UseApiKeysReturn => {
  const { enqueueSnackbar } = useSnackbar()
  const { storageApiClient } = useStorageApi()

  const [APIKeys, setAPIKeys] = useState<AccessKey[]>()

  const [generatedAPIKey, setGeneratedAPIKey] = useState<AccessKey>()
  const [isDeleteAPIKeyLoading, setIsDeleteAPIKeyLoading] = useState(false)

  const fetchAPIKeys = useCallback(async () => {
    try {
      const fetchedAPIKeys = await storageApiClient.listAccessKeys()
      setAPIKeys(fetchedAPIKeys.filter((key) => key.type === API_KEY_TYPE.GAMING))
    } catch (e) {
      console.error(e)
      setAPIKeys([])
    }
  }, [storageApiClient])

  useEffect(() => {
    fetchAPIKeys()
  }, [fetchAPIKeys])

  const generateAPIKey = useCallback(async () => {
    try {
      const newAPIKey = await storageApiClient.createAccessKey({ type: API_KEY_TYPE.GAMING })
      setGeneratedAPIKey(newAPIKey)
      await fetchAPIKeys()
      return newAPIKey
    } catch {
      enqueueSnackbar(t`Failed to generate API Key`, { variant: "error" })
      return
    }
  }, [storageApiClient, fetchAPIKeys, enqueueSnackbar])

  const deleteAPIKey = useCallback(
    async (keyId: string) => {
      setIsDeleteAPIKeyLoading(true)
      try {
        await storageApiClient.deleteAccessKey(keyId)
      } catch {
        enqueueSnackbar(t`Failed to delete API Key`, { variant: "error" })
      }
      await fetchAPIKeys()
      setIsDeleteAPIKeyLoading(false)
    },
    [storageApiClient, fetchAPIKeys, enqueueSnackbar]
  )

  const clearGeneratedAPIKey = useCallback(() => {
    setGeneratedAPIKey(undefined)
  }, [])

  return {
    APIKeys,
    generateAPIKey,
    generatedAPIKey,
    isDeleteAPIKeyLoading,
    deleteAPIKey,
    clearGeneratedAPIKey,
  }
}
