import { Box, Divider, styled, Typography } from "@mui/material"

// common

export const FullPageLayout = styled(Box)`
  padding: 8px 0px 128px 0px;
`

export const PageHeadingContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 16px;
`

export const PageHeading = styled(Typography)`
  flex: 1;
  margin-bottom: 4px;
  font-weight: 500;
`

export const HeadingDivider = styled(Divider)`
  margin: 16px 0;
`

// settings page styles

export const SettingsPageLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SettingsPageBox = styled(Box)`
  margin: 16px;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SettingsHeading = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 16px;
`

export const ProfileInfoContainer = styled(Box)`
  border: 1px solid #262626;
  border-radius: 5px;
`

export const APIKeysContainer = styled(Box)`
  border: 1px solid #262626;
  border-radius: 5px;
  padding: 12px;
`

export const EmailSettingsContainer = styled(Box)`
  border: 1px solid #262626;
  border-radius: 5px;
  padding: 12px;
`
