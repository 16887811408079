import Countly from "countly-sdk-web"
import React from "react"
import ReactDOM from "react-dom/client"

import App from "./App"
import { COUNTLY_APP_KEY, COUNTLY_APP_URL } from "./config"
import reportWebVitals from "./reportWebVitals"
;

(window as any).Countly = Countly

Countly.init({
  app_key: COUNTLY_APP_KEY,
  url: COUNTLY_APP_URL,
})

Countly.q.push(["track_sessions"])
Countly.q.push(["track_errors"])
Countly.q.push(["track_pageview"])

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
