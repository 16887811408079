import { styled } from "@mui/material"

export const ModalWrapper = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",

  backgroundColor: "#262626",
  borderRadius: "12px",
  transform: "translate(-50%, -50%)",
  "&:focus-visible": {
    outline: "none",
  },
  h3: {
    fontWeight: 400,
    fontSize: "32px",
  },
  h4: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#F5F5F5",
  },
  fontWeight: 500,
})

export const ModalLayout = styled("div")({
  width: "1050px",
  height: "520px",
  backgroundColor: "#262626",
  border: "1px solid #595959",
  padding: "20px",
  borderRadius: "12px",
  display: "grid",
  gridTemplateColumns: "repeat(2, 482px)",
  justifyContent: "space-between",
  "div > .token-id": {
    color: "#8C8C8C",
  },
  "div > .token-type-details": {
    display: "flex",
    "div + div": {
      marginLeft: "36px",
    },
  },
  justifyItems: "stretch",
})

export const ModalContent = styled("div")({
  overflow: "scroll",
  overflowX: "hidden",
  marginTop: "25px",
  paddingRight: "20px",
})

export const DropdownsContainer = styled("div")({
  paddingTop: "20px"
})

export const DataDropdown = styled("div")({
  backgroundColor: "#333333",
  borderRadius: "20px",
  padding: "15px",
  ".dropdown-header": {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    "&:hover": {
      filter: "opacity(0.8)",
    },
  },
  marginBottom: "5px",
})

export const DataDropdownChildren = styled("div")({
 paddingTop: "15px"
})

export const DataField = styled("div")({
  "p:first-child": {
    color: "#AFAFAF",
    marginBottom: "5px"
  },
  p: {
    color: "#F5F5F5",
    display: "flex",
    fontWeight: 500,
    lineHeight: "17px"
  },
  marginBottom: "8px",
  "&.copy": {
    cursor: "pointer",
    svg: {
      height: "13px",
      fill: "#DFF7C7",
    },
    "&:hover": {
      filter: "opacity(0.8)",
    },
    "&:active": {
      filter: "opacity(1)",
    },
  },
  ".token-type-wrapper": {
    color: "#B4FF68",
    background: "#595959",
    borderRadius: "20px",
    padding: "2px 0px 2px 7px ",
  },
})

export const DetailsGrid = styled("div")<{ gridColumns: number; width: string }>(
  ({ gridColumns, width }) => ({
    display: "grid",
    gridTemplateColumns: `repeat(${gridColumns}, ${width})`,
    justifyContent: "space-between",
  })
)

export const ListingRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  "p:first-child": {
    "&.copy": {
      cursor: "pointer",
      svg: {
        height: "13px",
        fill: "#DFF7C7",
      },
      "&:hover": {
        filter: "opacity(0.8)",
      },
      "&:active": {
        filter: "opacity(1)",
      },
    },
    width: "180px",
  },
  p: {
    minWidth: "70px",
  },
  ".listing-quantity": {
    width: "70px",
    textAlign: "center"
  },
  ".listing-price": {
    width: "160px",
    overflowX: "auto",
    textAlign: "center",
    whiteSpace: "nowrap"
  }
})

export const NFTImage = styled("img")({
  objectFit: "contain",
  maxWidth: "500px",
  maxHeight: "480px",
})

export const Line = styled("div")({
  width: "100%",
  borderBottom: "1px solid #333333",
  margin: "6px 0 20px 0px",
})

export const XIcon = styled("div")({
  position: "absolute",
  right: "10px",
  top: "10px",
  cursor: "pointer",
  "&:hover": {
    filter: "opacity(0.8)",
  },
  "&:active": {
    filter: "opacity(1)",
  },
})
