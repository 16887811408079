import { Trans } from "@lingui/macro"
import { Typography, Box, Divider, Button } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

import AllLootBoxes from "../../components/modules/lootBoxes/AllLootBoxes"
import { useWeb3Connection } from "../../contexts/Web3ConnectionContext"
import { ROUTE_LINKS } from "../../routes/routes"

const LootboxLandingPage = (): JSX.Element => {
  const { isLoggedIn } = useWeb3Connection()

  return (
    <Box pt={1} pb={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={0.5} flex={1}>
          <Typography sx={{ flex: 1, color: "#F5F5F5", fontWeight: 500 }} variant="h4">
            <Trans>VRF LootBox</Trans>
          </Typography>
          {isLoggedIn ? (
            <Link to={ROUTE_LINKS.DeployLootbox}>
              <Button variant="contained" size="small" data-cy="button-deploy-new-lootbox">
                <Trans>Deploy New LootBox</Trans>
              </Button>
            </Link>
          ) : null}
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box mb={4}>
        <AllLootBoxes />
      </Box>
    </Box>
  )
}

export default LootboxLandingPage
