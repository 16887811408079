import { IPFS_GATEWAY } from "../config"

export const centerEllipsis = (text: string, remaining = 6): string => {
  if (text.length <= remaining * 2) {
    return text
  }
  return `${text.substring(0, remaining)}...${text.substring(text.length - remaining)}`
}

export const endingEllipsis = (text: string, remaining = 6): string => {
  if (text.length <= remaining) {
    return text
  }
  return `${text.substring(0, remaining)}...`
}

export const capitalize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

// format number with comma
export const formatNumberWithComma = (value: number, numDecimals = 2): string => {
  return value.toLocaleString("en-US", {
    useGrouping: true,
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals,
  })
}

export const formatNumberWithMultiplier = (value: number): string => {
  if (value > 10 ** 12) return `${Math.floor(value / 10 ** 12)}T`
  if (value > 10 ** 9) return `${Math.floor(value / 10 ** 9)}B`
  if (value > 10 ** 6) return `${Math.floor(value / 10 ** 6)}M`
  if (value > 10 ** 3) return `${Math.floor(value / 10 ** 3)}K`
  return `${value}`
}

// if value is 5.11444, returns 5.11
// if value is 5.00 returns 5
export const truncateDecimals = (value: string, maxDecimalDigits: number): string => {
  const decimalIndex = value.indexOf(".")

  if (decimalIndex === -1) {
    // No decimal point, return the value as is
    return value
  }

  const integerPart = value.slice(0, decimalIndex)
  const decimalPart = value.slice(decimalIndex + 1)

  // Truncate decimal part to maxDecimalDigits
  const truncatedDecimalPart = decimalPart.slice(0, maxDecimalDigits)

  // Remove trailing zeros from the truncated decimal part
  const trimmedDecimalPart = truncatedDecimalPart.replace(/0+$/, "")

  // If the trimmed decimal part is empty, return only the integer part
  if (trimmedDecimalPart === "") {
    return integerPart
  }

  // Return the value with the truncated and trimmed decimal part
  return `${integerPart}.${trimmedDecimalPart}`
}

export const roundToDecimalPlaces = (num: number, decimalPlaces: number): number => {
  return Math.round(num * 10 ** decimalPlaces) / 10 ** decimalPlaces
}

export const getIPFSUri = (uri: string): string => {
  if (uri.startsWith("ipfs://")) {
    // old uri with ipfs gateway
    return `${IPFS_GATEWAY}/ipfs/${uri.replace("ipfs://", "")}`
  } else if (!uri.includes("://")) {
    // pure CID
    return `${IPFS_GATEWAY}/ipfs/${uri}`
  }
  return uri
}

export const delay = function (timeMilliSeconds: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setInterval(() => {
      resolve()
    }, timeMilliSeconds)
  })
}

export const formatTokenTypeDash = (tokenType: string): string => {
  if (tokenType.includes("ERC-")) return tokenType
  else {
    const arr = Array.from(tokenType)
    arr.splice(3, 0, "-").join("")
    return arr.toString().replaceAll(",", "")
  }
}