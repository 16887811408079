import { Trans } from "@lingui/macro"
import { Divider, Typography } from "@mui/material"

import ApiKeys from "../components/modules/settings/ApiKeys/ApiKeys"
import ProfileInfo from "../components/modules/settings/ProfileInfo/ProfileInfo"
import SubscriptionsView from "../components/modules/settings/SubscriptionsView/SubscriptionsView"

import {
  APIKeysContainer,
  EmailSettingsContainer,
  FullPageLayout,
  HeadingDivider,
  PageHeading,
  PageHeadingContainer,
  ProfileInfoContainer,
  SettingsHeading,
  SettingsPageBox,
  SettingsPageLayout,
} from "./layout.styles"

const SettingsPage = (): JSX.Element => {
  return (
    <FullPageLayout>
      <PageHeadingContainer>
        <PageHeading variant="h4">
          <Trans>Settings</Trans>
        </PageHeading>
      </PageHeadingContainer>
      <HeadingDivider />
      <SettingsPageLayout>
        <SettingsPageBox>
          <SettingsHeading>
            <Typography variant="h6">
              <Trans>Profile information</Trans>
            </Typography>
            <Divider />
          </SettingsHeading>
          <ProfileInfoContainer>
            <ProfileInfo isEdit={true} />
          </ProfileInfoContainer>
        </SettingsPageBox>
        <SettingsPageBox>
          <SettingsHeading>
            <Typography variant="h6">
              <Trans>API Keys</Trans>
            </Typography>
            <Divider />
          </SettingsHeading>
          <APIKeysContainer>
            <ApiKeys />
          </APIKeysContainer>
        </SettingsPageBox>
        <SettingsPageBox>
          <SettingsHeading>
            <Typography variant="h6">
              <Trans>Email subscriptions</Trans>
            </Typography>
            <Divider />
          </SettingsHeading>
          <EmailSettingsContainer>
            <SubscriptionsView />
          </EmailSettingsContainer>
        </SettingsPageBox>
      </SettingsPageLayout>
    </FullPageLayout>
  )
}

export default SettingsPage
