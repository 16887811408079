import { Dialog, Paper, styled } from "@mui/material"
import type { PropsWithChildren } from "react"

interface IProps {
  open: boolean
  onClose: () => void
  width?: string
}

const ModalBody = styled(Paper)<{ width?: string }>(({ width }) => ({
  border: "1px solid #262626",
  width,
  borderRadius: "12px",
}))

const CustomModal = ({
  open,
  onClose,
  width,
  children,
}: PropsWithChildren<IProps>): JSX.Element => {
  return (
    <Dialog open={open} onClose={onClose}>
      <ModalBody width={width}>{children}</ModalBody>
    </Dialog>
  )
}

export default CustomModal
