import { Trans } from "@lingui/macro"
import { ContentCopy } from "@mui/icons-material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Typography, Box, Divider, Breadcrumbs, Button, CircularProgress } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { Link, useParams } from "react-router-dom"

import CollectionNFTs from "../../components/modules/nftLaunchpad/CollectionNFTs"
import { useNFTs } from "../../contexts/NFTsContext"
import { useWeb3Connection } from "../../contexts/Web3ConnectionContext"
import { ROUTE_LINKS } from "../../routes/routes"
import { BANNER_HEIGHT, DRAWER_WIDTH } from "../../themes/theme"
import { centerEllipsis, getIPFSUri } from "../../utils/Helpers"
import { getNetwork } from "../../utils/NetworkHelpers"

const NFTsCollectionPage = (): JSX.Element | null => {
  const { enqueueSnackbar } = useSnackbar()

  const { NFTsProject, NFTsCollection, hasLoadedCollection } = useNFTs()
  const { networks } = useWeb3Connection()
  const { collectionId } = useParams<{ collectionId: string }>()

  if (!NFTsProject || !NFTsCollection) return null

  const { banner, name, chain_id, description, type, logo, contract_address } = NFTsCollection

  if (!hasLoadedCollection || collectionId !== NFTsCollection.id) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box pt={1} pb={1}>
      {banner ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: DRAWER_WIDTH,
            width: `calc(100vw - ${DRAWER_WIDTH}px)`,
            zIndex: -100,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: BANNER_HEIGHT,
              zIndex: -99,
              background: `linear-gradient(180deg, rgba(20, 20, 20, 0) 10.94%, rgba(20, 20, 20, 0.135) 29.2%, rgba(20, 20, 20, 0.285) 43%, rgba(20, 20, 20, 0.435) 56.36%, rgba(20, 20, 20, 0.83) 82.19%, #141414 100%)`,
            }}
          ></Box>
          <Box sx={{ zIndex: -100, position: "absolute", width: "100%" }}>
            <img
              style={{ width: "100%", height: BANNER_HEIGHT, objectFit: "cover", opacity: 0.5 }}
              src={getIPFSUri(banner)}
            />
          </Box>
        </Box>
      ) : null}
      <Box
        sx={{
          display: "flex",
          flex: 1,
          ml: 2,
          mr: 2,
        }}
      >
        <Box mb={0.5} flex={1}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ mt: 1, mb: 1 }}>
            <Link
              style={{ color: "#F5F5F5", textDecoration: "none" }}
              to={ROUTE_LINKS.NFTsOnProject(NFTsProject?.projectId)}
              data-cy="link-breadcrumb-project-collections"
            >
              {NFTsProject?.name || "Project"}
            </Link>
            <Link
              style={{ color: "#F5F5F5", textDecoration: "none" }}
              to={ROUTE_LINKS.NFTsOnCollection(NFTsProject?.projectId, NFTsCollection.id)}
              data-cy="link-breadcrumb-collection-nfts"
            >
              {name || "Collection"}
            </Link>
            <Typography data-cy="label-breadcrumb-nfts">NFTs</Typography>
          </Breadcrumbs>
          <Typography sx={{ flex: 1, color: "#F5F5F5", fontWeight: 500 }} variant="h4">
            {NFTsCollection?.name}
          </Typography>
          {chain_id ? (
            <Typography sx={{ flex: 1, mt: 0.5, color: "#F5F5F5" }} variant="body1">
              {getNetwork(chain_id, networks)?.label}
            </Typography>
          ) : null}
          {description ? (
            <Typography sx={{ flex: 1, mt: 2, color: "#F5F5F5" }} variant="body1">
              {description}
            </Typography>
          ) : null}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mt={4}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              {logo ? (
                <Box sx={{ width: 60, height: 60 }}>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                      border: "1px solid #F5F5F5",
                    }}
                    src={getIPFSUri(logo)}
                  />
                </Box>
              ) : null}
              <Box sx={{ p: "8px 16px", background: "#262626", borderRadius: 5 }}>
                <Typography color="#A5A5A5">Address</Typography>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography color="#F5F5F5" data-cy="label-collection-address">
                    {centerEllipsis(contract_address, 8)}
                  </Typography>
                  <ContentCopy
                    sx={{ fontSize: "14px", cursor: "pointer" }}
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(contract_address)
                      enqueueSnackbar("Contract address copied", { variant: "success" })
                    }}
                    data-cy="button-copy-collection-address"
                  />
                </Box>
              </Box>
              <Box sx={{ p: "8px 16px", background: "#262626", borderRadius: 5 }}>
                <Typography color="#A5A5A5">ID</Typography>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography color="#F5F5F5" data-cy="label-collection-id">
                    {centerEllipsis(collectionId, 8)}
                  </Typography>
                  <ContentCopy
                    sx={{ fontSize: "14px", cursor: "pointer" }}
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(collectionId)
                      enqueueSnackbar("Collection ID copied", { variant: "success" })
                    }}
                    data-cy="button-copy-collection-id"
                  />
                </Box>
              </Box>
              <Box sx={{ p: "8px 16px", background: "#262626", borderRadius: 5 }}>
                <Typography color="#A5A5A5">Type</Typography>
                <Typography color="#F5F5F5" data-cy="label-collection-token-type">
                  {type}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Link
                to={ROUTE_LINKS.NFTsNFTCreate(NFTsProject?.projectId, NFTsCollection.id)}
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" data-cy="button-mint-new-nft" sx={{ borderRadius: 20 }}>
                  <Trans>Mint New NFT</Trans>
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box mb={4}>
        <CollectionNFTs />
      </Box>
    </Box>
  )
}

export default NFTsCollectionPage
