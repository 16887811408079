import { LoadingButton } from "@mui/lab"
import { Box, styled, Typography } from "@mui/material"

// profile info modal

export const ProfileInfoModalBox = styled(Box)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 580px;
  border: 1px solid #262626;
  box-shadow: 24;
  background-color: #121212;
  border-radius: 8px;
  padding: 32px;
  overflow: auto;
  height: fit-content;
  max-height: 100vh;
`

export const ProfileInfoHeading = styled(Typography)`
  margin: 16px;
  text-align: center;
`

export const ProfileInfoSubHeading = styled(Typography)`
  margin-bottom: 32px;
  text-align: center;
`

// profile info sections

export const InfoLayout = styled(Box)<{ edit: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ edit }) => (edit === "true" ? "24px" : "0px")};
  gap: 24px;
  padding: ${({ edit }) => (edit === "true" ? "24px 24px 2px 24px" : "")};
`

export const FormTwoRowContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`

export const ProfileInfoFooter = styled(Box)<{ edit: string }>`
  display: flex;
  margin-top: ${({ edit }) => (edit === "true" ? "8px" : "16px")};
  justify-content: ${({ edit }) => (edit === "true" ? "flex-end" : "center")};
  padding-right: ${({ edit }) => (edit === "true" ? "24px" : "0px")};
  background-color: ${({ edit }) => (edit === "true" ? "#161616" : "")};
  border-top: ${({ edit }) => (edit === "true" ? "1px solid #262626" : "")};
`

export const LoaderFooterButton = styled(LoadingButton)<{ edit: string }>`
  margin: ${({ edit }) => (edit === "true" ? "16px 0" : "8px 0px 16px 0px")};
  width: ${({ edit }) => (edit === "true" ? "160px" : "100%")};
`

export const InfoFooterLayout = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
`

export const InfoFooterText = styled(Typography)`
  font-style: italic;
  color: #bfbfbf;
  text-align: center;
`
