import { Trans } from "@lingui/macro"
import { Box, Typography } from "@mui/material"
import React from "react"

import type { Network } from "../../../utils/NetworkHelpers"
import { getNetworkLabels } from "../../../utils/NetworkHelpers"

interface IProps {
  networks: Network[]
}

const NetworkSupport: React.FC<IProps> = ({ networks }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" data-cy="box-network-unsupported">
      <Typography textAlign="center" fontSize={24} mt={1}>
        <Trans>Network unsupported</Trans>
      </Typography>
      <Typography textAlign="left" fontSize={16} mt={2}>
        <Trans>
          Your wallet is currently connected to an unsupported network. To continue, please switch
          to any of the networks listed below:
        </Trans>
      </Typography>
      <Box
        mt={3}
        mb={1}
        pl={4}
        pr={4}
        pt={2}
        pb={2}
        sx={{
          borderRadius: "12px",
          border: "1px solid #595959",
          background: "#262626",
          height: 160,
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {getNetworkLabels(networks).map((n) => (
          <Box key={`${n.chain}${n.network}`} display="flex">
            <Typography variant="body1">
              {n.chain} {!n.hasTestnet && n.network}
            </Typography>
            {n.hasTestnet && (
              <Typography color="#8C8C8C" sx={{ ml: 1 }}>
                {" "}
                (+ {n.testNetworkLabel || ""} Testnet){" "}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default NetworkSupport
