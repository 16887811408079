import { Trans } from "@lingui/macro"
import { Box, Typography } from "@mui/material"
import type { BigNumber } from "ethers"
import { ethers } from "ethers"
import { useState, useEffect } from "react"

import { KECCAK_256_DO_NOT_UNWRAP } from "../../../../../contexts/LootboxContext"
import { useWeb3Connection } from "../../../../../contexts/Web3ConnectionContext"
import GenericERC1155 from "../../../../../contracts/Generic/GenericERC1155.json"
import GenericERC721 from "../../../../../contracts/Generic/GenericERC721.json"
import { TOKEN_TYPES, type TokenType } from "../../../../../types/lootbox"
import { getIPFSUri } from "../../../../../utils/Helpers"

import ERC20Reward from "./ERC20Reward"

interface IProps {
  address: string
  tokenId: BigNumber
  tokenType: TokenType
  amount?: string
}

const RewardCard: React.FC<IProps> = ({ address, tokenId, tokenType, amount }) => {
  const { signer } = useWeb3Connection()
  const [nftData, setNftData] = useState<{
    nft?: {
      name: string
      image: string
      description?: string
    }
    erc20Address?: string
  }>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchNFTData(): Promise<void> {
      try {
        const contract = new ethers.Contract(
          address,
          tokenType === 2 ? GenericERC721 : GenericERC1155,
          signer
        )

        let isERC20VariableDistribution = false
        try {
          const doNotUnwrapResponse = await contract.DO_NOT_UNWRAP()

          if (doNotUnwrapResponse === KECCAK_256_DO_NOT_UNWRAP) {
            isERC20VariableDistribution = true
          }
        } catch (error) {
          // no need to handles
          // DO_NOT_UNWRAP function is optional at contract
        }

        if (isERC20VariableDistribution) {
          const erc20Address = (await contract.underlying()) as string
          setNftData({ erc20Address })
        } else {
          const tokenURI = await (tokenType === 2
            ? contract.tokenURI(tokenId)
            : contract.uri(tokenId))

          if (tokenURI) {
            const data = await (await fetch(tokenURI.replace("{id}", tokenId))).json()
            setNftData({ nft: data })
          }
        }
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching NFT data:", error)
        setIsLoading(false)
      }
    }

    if (address && tokenId !== undefined && signer) {
      fetchNFTData()
    }
  }, [address, tokenId, tokenType, signer])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        width: "100%",
        height: 260,
        border: "1px solid #BFBFBF",
        borderRadius: "10px",
        maxWidth: 200,
      }}
    >
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
        >
          <Typography>
            <Trans>Loading...</Trans>
          </Typography>
        </Box>
      ) : nftData?.erc20Address ? (
        <ERC20Reward address={nftData.erc20Address} amount={tokenId} />
      ) : nftData?.nft ? (
        <>
          <img
            src={getIPFSUri(nftData.nft.image)}
            alt={nftData.nft.name}
            style={{ maxWidth: "inherit", borderRadius: "10px", height: "auto" }}
          />
          <Box
            flex={1}
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography>{nftData.nft.name}</Typography>
            {amount ? <Typography fontSize={16}>Quantity: {amount}</Typography> : null}
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
        >
          <Typography fontSize={22} align="center">
            <Trans>{TOKEN_TYPES[tokenType]} token</Trans>
          </Typography>
          <Typography align="center">
            <Trans>Token # {tokenId.toString()}</Trans>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default RewardCard
