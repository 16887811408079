import { Trans } from "@lingui/macro"
import { Divider } from "@mui/material"

import { useCallback, useState } from "react"
import type { FC } from "react"
import { SoftButton } from "../../../elements/SoftButton"

import ApiKeyRow from "./ApiKeyRow"
import {
  APIKeyCreateContainer,
  APIKeyHeaderText,
  ApiKeysContainer,
  APIKeysListContainer,
  APIKeysTableHeaderRow,
  CreateAPIKeyText,
  NoAPIKeysContainer,
  NoAPIKeyText,
} from "./ApiKeys.styles"
import { useApiKeys } from "./useApiKeys"
import GenerateApiKeyModal from "./GenerateApiKeyModal"

const ApiKeys: FC = () => {
  const {
    APIKeys,
    generateAPIKey,
    isDeleteAPIKeyLoading,
    deleteAPIKey,
    generatedAPIKey,
    clearGeneratedAPIKey,
  } = useApiKeys()

  const [isAPIKeyModalOpen, setIsAPIKeyModalOpen] = useState(false)

  const handleGenerateApiKey = useCallback(async () => {
    setIsAPIKeyModalOpen(true)
    const newApiKey = await generateAPIKey()
    if (!newApiKey) setIsAPIKeyModalOpen(false)
  }, [generateAPIKey])

  const closeGenerateAPIKeyModal = useCallback(() => {
    setIsAPIKeyModalOpen(false)
    clearGeneratedAPIKey()
  }, [clearGeneratedAPIKey])

  return (
    <>
      <ApiKeysContainer>
        <APIKeyCreateContainer>
          <CreateAPIKeyText>
            <Trans>Create a new API key</Trans>
          </CreateAPIKeyText>
          <SoftButton onClick={handleGenerateApiKey}>
            <Trans>Generate API Key</Trans>
          </SoftButton>
        </APIKeyCreateContainer>
        <Divider />
        <APIKeysListContainer>
          {APIKeys?.length ? (
            <>
              <APIKeysTableHeaderRow>
                <APIKeyHeaderText variant="body2">
                  <Trans>API Key</Trans>
                </APIKeyHeaderText>
                <APIKeyHeaderText variant="body2">
                  <Trans>Created at</Trans>
                </APIKeyHeaderText>
              </APIKeysTableHeaderRow>
              {APIKeys.map((APIKey) => (
                <ApiKeyRow
                  APIKey={APIKey}
                  deleteAPIKey={deleteAPIKey}
                  isDeleteAPIKeyLoading={isDeleteAPIKeyLoading}
                />
              ))}
            </>
          ) : (
            <NoAPIKeysContainer>
              <NoAPIKeyText variant="body2">
                <Trans>No API Keys generated</Trans>
              </NoAPIKeyText>
            </NoAPIKeysContainer>
          )}
        </APIKeysListContainer>
      </ApiKeysContainer>
      {/* Generate API Key modal */}
      <GenerateApiKeyModal
        generatedAPIKey={generatedAPIKey}
        isAPIKeyModalOpen={isAPIKeyModalOpen}
        closeGenerateAPIKeyModal={closeGenerateAPIKeyModal}
      />
    </>
  )
}

export default ApiKeys
