import type { SvgIconProps } from "@mui/material"
import { SvgIcon } from "@mui/material"

export function CircleBlankIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 4.48584C8.084 4.48584 4.5 8.06984 4.5 12.4858C4.5 16.9018 8.084 20.4858 12.5 20.4858C16.916 20.4858 20.5 16.9018 20.5 12.4858C20.5 8.06984 16.916 4.48584 12.5 4.48584ZM12.5 18.8858C8.964 18.8858 6.1 16.0218 6.1 12.4858C6.1 8.94984 8.964 6.08584 12.5 6.08584C16.036 6.08584 18.9 8.94984 18.9 12.4858C18.9 16.0218 16.036 18.8858 12.5 18.8858Z"
          fill="#8C8C8C"
        />
      </svg>
    </SvgIcon>
  )
}
