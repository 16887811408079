import { t } from "@lingui/macro"
import axios from "axios"
import type { BigNumber } from "ethers"
import { ethers } from "ethers"
import { useSnackbar } from "notistack"
import React, { useCallback, useEffect, useMemo, useState } from "react"

import ERC1155Abi from "../contracts/Generic/GenericERC1155.json"
import GenericERC20 from "../contracts/Generic/GenericERC20.json"
import ERC1155ERC20WrapperFactory from "../contracts/Lootbox/ERC1155ERC20WrapperFactory.json"
import Lootbox from "../contracts/Lootbox/Lootbox.json"
import LootboxFactory from "../contracts/Lootbox/LootboxFactory.json"
import LootboxView from "../contracts/Lootbox/LootboxView.json"
import type {
  LootboxData,
  LootboxInventoryResponse,
  LootboxInventoryResult,
} from "../types/lootbox"
import type { Network } from "../utils/NetworkHelpers"

import { useCountly } from "./CountlyContext"
import { useWeb3Connection } from "./Web3ConnectionContext"

export type LootboxContextType = {
  networkData?: Network
  isLoadingLootboxes: boolean
  lootboxes?: LootboxData[]
  deployLootbox: (uri: string) => Promise<string | undefined>
  lootboxToManage?: LootboxData
  lootboxToOpen?: string
  lootboxAllowedTokens?: string[]
  lootboxInventory?: LootboxInventoryResponse
  lootboxSuppliers?: string[]
  setLootboxToManage: (lootbox: LootboxData | undefined) => void
  addTokenAddressesToLootbox: (tokenAddresses: string[]) => Promise<boolean | undefined>
  updateDepositorAddressesToLootbox: (
    tokenAddressesToAdd: string[],
    depositorAddressesToRemove: string[]
  ) => Promise<boolean | undefined>
  isInventoryLoading: boolean
  updateRewards: (tokens: string[], ids: number[], amountsPerUnit: BigNumber[]) => Promise<void>
  activateERC20Distributions: (
    tokenAddress: string,
    tokens: number[],
    bundles: number[]
  ) => Promise<void>
  updateERC20Distributions: (
    tokenAddress: string,
    wrapperAddress: string,
    tokens: number[],
    bundles: number[]
  ) => Promise<void>
  mintAndTransferToPlayers: (
    playerAddresses: string[],
    lootboxTypes: number[],
    lootboxAmounts: number[]
  ) => Promise<void>
  setLootboxToOpen: (lootboxAddress: string | undefined) => void
}

export const KECCAK_256_DO_NOT_UNWRAP =
  "0xb7dcc8d4f8c7089b2848886d8b8cd5cdea2b9e39c8b76e751a2ad908f5c8469d"

type LootboxContextProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

const LootboxContext = React.createContext<LootboxContextType | undefined>(undefined)

const LootboxProvider = ({ children }: LootboxContextProviderProps): JSX.Element => {
  const { address, provider, network, isLoggedIn, networks } = useWeb3Connection()
  const { trackEvent } = useCountly()
  const signer = useMemo(() => provider?.getSigner(0), [provider])

  const networkData = useMemo(
    () =>
      networks.find(
        (newNetwork) =>
          newNetwork.chainId === network?.chainId && !!newNetwork.lootboxFactoryAddress
      ),
    [network, networks]
  )

  const [isLoadingLootboxes, setIsLoadingLootboxes] = useState(false)

  const [lootboxes, setLootboxes] = useState<LootboxData[]>()
  const [lootboxToManage, setLootboxToManage] = useState<LootboxData>()
  const [lootboxAllowedTokens, setLootboxAllowedTokens] = useState<string[]>()
  const [lootboxInventory, setLootboxInventory] = useState<LootboxInventoryResponse>()
  const [isInventoryLoading, setIsInventoryLoading] = useState(false)
  const [lootboxSuppliers, setLootboxSuppliers] = useState<string[]>()

  const [lootboxToOpen, setLootboxToOpen] = useState<string>()

  const { enqueueSnackbar } = useSnackbar()

  const lootboxFactoryContract = useMemo(() => {
    if (!networkData?.lootboxFactoryAddress || !signer) return
    return new ethers.Contract(networkData.lootboxFactoryAddress, LootboxFactory.abi, signer)
  }, [networkData?.lootboxFactoryAddress, signer])

  // get lootboxes using lootbox factory contract
  const getLootboxes = useCallback(async () => {
    if (!networkData?.lootboxFactoryAddress || !signer || !lootboxFactoryContract) return

    const walletAddress = await signer?.getAddress()

    try {
      setIsLoadingLootboxes(true)
      const lootboxAddresses: string[] = []
      let lootboxAddress: string = ""
      let i = 0

      while (lootboxAddress !== ethers.constants.AddressZero) {
        lootboxAddress = await lootboxFactoryContract.getLootbox(walletAddress, i)
        if (lootboxAddress === ethers.constants.AddressZero) break
        lootboxAddresses.push(lootboxAddress)
        i++
      }

      const lootboxesWithMetadata = await Promise.all(
        lootboxAddresses.map(async (newLootboxAddress): Promise<LootboxData> => {
          const lootboxViewContract = new ethers.Contract(
            newLootboxAddress,
            LootboxView.abi,
            signer
          )

          try {
            const uri = await lootboxViewContract.uri("0")
            if (!uri) return { address: newLootboxAddress }

            const { data } = await axios.get(uri)
            return {
              address: newLootboxAddress,
              name: data.name,
              description: data.description,
              image: data.image,
            }
          } catch {
            return { address: newLootboxAddress }
          }
        })
      )

      setIsLoadingLootboxes(false)
      setLootboxes([...lootboxesWithMetadata])
      return lootboxAddresses
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t`Failed to fetch LootBoxes`, { variant: "error", autoHideDuration: 5000 })
    }
  }, [networkData?.lootboxFactoryAddress, lootboxFactoryContract, signer, enqueueSnackbar])

  useEffect(() => {
    getLootboxes()
  }, [getLootboxes])

  // deploy a new LootBox using lootbox factory contract
  const deployLootbox = useCallback(
    async (uri: string) => {
      if (!lootboxFactoryContract || !lootboxes || !isLoggedIn) return
      const newLootboxTx = await lootboxFactoryContract.deployLootbox(uri, lootboxes.length)
      await newLootboxTx.wait()

      const newLootboxes = await getLootboxes()
      if (!newLootboxes?.length) return
      const newLootbox = newLootboxes[newLootboxes.length - 1]

      // track event
      trackEvent({
        key: "lootbox-created",
        segmentation: { lootbox_address: newLootbox },
      })
      return newLootboxes[newLootboxes.length - 1]
    },
    [lootboxFactoryContract, lootboxes, getLootboxes, isLoggedIn, trackEvent]
  )

  const [lootboxContract, lootboxViewContract] = useMemo(() => {
    if (!lootboxToManage) return []
    return [
      new ethers.Contract(lootboxToManage.address, Lootbox.abi, signer),
      new ethers.Contract(lootboxToManage.address, LootboxView.abi, signer),
    ]
  }, [lootboxToManage, signer])

  // get lootbox allowed tokens using lootbox contract
  const getLootboxAllowedTokens = useCallback(async () => {
    if (!lootboxViewContract) {
      setLootboxAllowedTokens(undefined)
      return
    }
    try {
      const allowedTokens = await lootboxViewContract.getAllowedTokens()
      setLootboxAllowedTokens(allowedTokens)
      return allowedTokens
    } catch (err) {
      console.error(err)
      return
    }
  }, [lootboxViewContract])

  // get lootbox suppliers using lootbox contract
  const getLootboxSuppliers = useCallback(async () => {
    if (!lootboxViewContract) {
      setLootboxSuppliers(undefined)
      return
    }
    try {
      const suppliers = await lootboxViewContract.getSuppliers()
      setLootboxSuppliers(suppliers)
      return suppliers
    } catch (err) {
      console.error(err)
      return
    }
  }, [lootboxViewContract])

  // add token addresses to lootbox using lootbox contract and lootbox address
  const addTokenAddressesToLootbox = useCallback(
    async (tokenAddresses: string[]) => {
      if (!lootboxContract || !tokenAddresses.length) return
      try {
        const tokenAddressTx = await lootboxContract.addTokens(tokenAddresses)
        await tokenAddressTx.wait()
        await getLootboxAllowedTokens()

        // track event
        trackEvent({
          key: "lootbox-add-whitelisted-addresses",
          segmentation: { lootbox_address: lootboxToManage?.address },
        })

        enqueueSnackbar("Token addresses have been updated", { variant: "success" })
        return true
      } catch (err) {
        console.error(err)
        enqueueSnackbar("Failed to add token addresses", { variant: "error" })
        return false
      }
    },
    [lootboxContract, getLootboxAllowedTokens, enqueueSnackbar, trackEvent, lootboxToManage]
  )

  // add depositor addresses to lootbox using lootbox contract and lootbox address
  const updateDepositorAddressesToLootbox = useCallback(
    async (depositorAddressesToAdd: string[], depositorAddressesToRemove: string[]) => {
      if (!lootboxContract) return
      if (!depositorAddressesToAdd.length && !depositorAddressesToRemove.length) return
      try {
        if (depositorAddressesToAdd.length) {
          const depositorAddressTx = await lootboxContract.addSuppliers(depositorAddressesToAdd)
          await depositorAddressTx.wait()
        }
        if (depositorAddressesToRemove.length) {
          const depositorAddressTx = await lootboxContract.removeSuppliers(
            depositorAddressesToRemove
          )
          await depositorAddressTx.wait()
        }
        await getLootboxSuppliers()

        // track event
        trackEvent({
          key: "lootbox-add-depositor-addresses",
          segmentation: { lootbox_address: lootboxToManage?.address },
        })

        enqueueSnackbar("Depositor addresses have been updated", { variant: "success" })
        return true
      } catch (err) {
        console.error(err)
        enqueueSnackbar("Failed to add depositor addresses", { variant: "error" })
        return false
      }
    },
    [lootboxContract, getLootboxSuppliers, enqueueSnackbar, trackEvent, lootboxToManage]
  )

  // get lootbox inventory using lootbox contract
  const getLootboxInventory = useCallback(async () => {
    if (!lootboxViewContract) {
      setLootboxInventory(undefined)
      return
    }
    try {
      const inventory = (await lootboxViewContract.getInventory()) as {
        result: LootboxInventoryResult[]
        leftoversResult?: LootboxInventoryResult[]
      }
      setLootboxInventory(inventory)
      return inventory
    } catch (err) {
      console.error(err)
      return
    }
  }, [lootboxViewContract])

  useEffect(() => {
    if (isLoggedIn) {
      getLootboxAllowedTokens()
      setIsInventoryLoading(true)
      getLootboxInventory().finally(() => {
        setIsInventoryLoading(false)
      })
      getLootboxSuppliers()
    }
  }, [getLootboxSuppliers, getLootboxAllowedTokens, isLoggedIn, getLootboxInventory])

  const updateRewards = useCallback(
    async (tokens: string[], ids: number[], amountsPerUnit: BigNumber[]) => {
      if (!lootboxContract) return
      if (tokens.length !== ids.length || ids.length !== amountsPerUnit.length) return
      try {
        const updateRewardsTx = await lootboxContract.setAmountsPerUnit(tokens, ids, amountsPerUnit)
        await updateRewardsTx.wait()
        await getLootboxInventory()

        // track event
        trackEvent({
          key: "lootbox-update-rewards",
          segmentation: { lootbox_address: lootboxToManage?.address },
        })
      } catch (err) {
        console.error(err)
        throw new Error(t`Failed to update LootBox rewards`)
      }
    },
    [lootboxContract, getLootboxInventory, trackEvent, lootboxToManage]
  )

  // Activate ERC20 distributions
  const activateERC20Distributions = useCallback(
    async (tokenAddress: string, tokens: number[], bundles: number[]) => {
      if (!lootboxToManage) return
      try {
        const erc20Contract = new ethers.Contract(tokenAddress, GenericERC20, signer)
        const decimals = await erc20Contract.decimals()

        const wrapperContract = new ethers.Contract(
          network?.lootboxERC20WrapperAddress || "",
          ERC1155ERC20WrapperFactory.abi,
          signer
        )

        const deployTx = await wrapperContract.deployWrapperWithSetup(
          tokenAddress,
          address,
          lootboxToManage.address,
          tokens.map((token) => ethers.utils.parseUnits(token.toString(), decimals ?? 18)), // amount of tokens in each bundle
          bundles.map((bundle) => ethers.BigNumber.from(bundle)) // number of bundles
        )

        await deployTx.wait()
        await getLootboxInventory()

        // track event
        trackEvent({
          key: "lootbox-deploy-erc20-distributions",
          segmentation: { lootbox_address: lootboxToManage?.address },
        })
      } catch (err) {
        console.error(err)
        throw new Error(t`Failed to update LootBox rewards`)
      }
    },
    [getLootboxInventory, address, network, signer, trackEvent, lootboxToManage]
  )

  // update ERC20 rewards
  const updateERC20Distributions = useCallback(
    async (tokenAddress: string, wrapperAddress: string, tokens: number[], bundles: number[]) => {
      if (!signer || !lootboxToManage) return

      try {
        const erc20Contract = new ethers.Contract(tokenAddress, GenericERC20, signer)
        const decimals = await erc20Contract.decimals()

        const wrapperContract = new ethers.Contract(wrapperAddress, ERC1155Abi, signer)

        const erc20Tx = await wrapperContract.mintBatchAndTransfer(
          address,
          lootboxToManage.address,
          tokens.map((token) => ethers.utils.parseUnits(token.toString(), decimals ?? 18)),
          bundles.map((bundle) => ethers.BigNumber.from(bundle)),
          KECCAK_256_DO_NOT_UNWRAP
        )
        await erc20Tx.wait()
        await getLootboxInventory()

        // track event
        trackEvent({
          key: "lootbox-update-erc20-distributions",
          segmentation: { lootbox_address: lootboxToManage?.address },
        })
      } catch (error) {
        console.error(error)
        throw new Error(t`Failed to update LootBox rewards`)
      }
    },
    [signer, lootboxToManage, address, getLootboxInventory, trackEvent]
  )

  // mint lootboxes for players using lootbox contract
  const mintAndTransferToPlayers = useCallback(
    async (playerAddresses: string[], lootboxTypes: number[], lootboxAmounts: number[]) => {
      if (!lootboxContract) return
      if (
        playerAddresses.length !== lootboxTypes.length ||
        lootboxTypes.length !== lootboxAmounts.length
      )
        throw new Error(t`Lootbox addresses and awards are not valid`)

      try {
        const playerMintsTx = await lootboxContract.mintToMany(
          playerAddresses,
          lootboxTypes,
          lootboxAmounts
        )
        await playerMintsTx.wait()

        // track event
        trackEvent({
          key: "lootbox-transfer-to-players",
          segmentation: { lootbox_address: lootboxToManage?.address },
        })
      } catch (err) {
        console.error(err)
        throw new Error(t`Failed to mint lootboxes for players`)
      }
    },
    [lootboxContract, trackEvent, lootboxToManage]
  )

  return (
    <LootboxContext.Provider
      value={{
        networkData,
        isLoadingLootboxes,
        lootboxes,
        deployLootbox,
        lootboxToManage,
        lootboxToOpen,
        lootboxAllowedTokens,
        lootboxInventory,
        lootboxSuppliers,
        setLootboxToManage,
        isInventoryLoading,
        addTokenAddressesToLootbox,
        updateDepositorAddressesToLootbox,
        updateRewards,
        activateERC20Distributions,
        updateERC20Distributions,
        mintAndTransferToPlayers,
        setLootboxToOpen,
      }}
    >
      {children}
    </LootboxContext.Provider>
  )
}

function useLootbox(): LootboxContextType {
  const context = React.useContext(LootboxContext)
  if (context === undefined) {
    throw new Error("useLootbox must be used within a LootboxContext")
  }
  return context
}

export { LootboxProvider, useLootbox }
