import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material"

import { SoftButton } from "../../../elements/SoftButton"

export const ApiKeysContainer = styled(Box)`
  margin: 16px;
`

export const APIKeyCreateContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`

export const CreateAPIKeyText = styled(Typography)`
  color: #8c8c8c;
`

export const NoAPIKeysContainer = styled(Box)`
  padding: 36px 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NoAPIKeyText = styled(Typography)`
  color: #8c8c8c;
`

export const APIKeysListContainer = styled(Box)`
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const APIKeysTableHeaderRow = styled(Box)`
  display: grid;
  grid-template-columns: 6fr 4fr 1fr 1fr;
  padding: 8px 20px;
  align-items: center;
`

export const APIKeysTableRow = styled(APIKeysTableHeaderRow)`
  border-radius: 8px;
  background-color: #161616;
  padding: 2px 20px;
`

export const APIKeyHeaderText = styled(Typography)`
  color: #595959;
  font-weight: 500;
`

export const APIKeyText = styled(Typography)`
  color: #bfbfbf;
`

export const CopyButton = styled(Button)`
  color: #dff7c7;
`

export const DropdownContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`

export const APIColumnText = styled(Typography)`
  color: #8c8c8c;
`

export const DropdownIcon = styled(IconButton)`
  width: 40px;
  color: #595959;
`

export const DropdownMenu = styled(Menu)`
  ul {
    padding: 0px;
  }
`

export const DeleteMenuButton = styled(MenuItem)`
  color: #ffa39e;
  height: 40px;
  padding: 8px 24px;
`

// API key generation

export const GenerateAPIKeyLoaderContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin: 48px 0;
`

export const GenerateAPIKeyBody = styled(Box)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 620px;
  border: 1px solid #262626;
  box-shadow: 24;
  background-color: #121212;
  border-radius: 8px;
  padding: 24px 32px;
  overflow: auto;
  height: fit-content;
  max-height: 100vh;
`

export const GenerateAPIKeyInfoBox = styled(Box)`
  padding: 16px 16px;
  border: 1px solid #262626;
  border-radius: 8px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const GenerateAPIKeyBoxTitle = styled(Typography)`
  color: #8c8c8c;
  font-weight: 500;
`

export const APISecretBox = styled(Box)`
  background-color: #161616;
  border: 1px solid #262626;
  padding: 16px 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`

export const SecretText = styled(Typography)<{ singleLine?: boolean }>`
  color: #8c8c8c;
  white-space: ${({ singleLine }) => (singleLine ? "nowrap" : "pre-wrap")};
  overflow: ${({ singleLine }) => (singleLine ? "hidden" : "visible")};
  text-overflow: ${({ singleLine }) => (singleLine ? "ellipsis" : "clip")};
  word-break: ${({ singleLine }) => (singleLine ? "normal" : "break-all")};
  overflow-wrap: ${({ singleLine }) => (singleLine ? "normal" : "break-word")};
`

export const SecretCopyButton = styled(SoftButton)`
  background-color: transparent;
  border: 1px solid #262626;
`

export const SeeToggleButton = styled("span")`
  cursor: pointer;
  color: #dff7c7;
  align-self: center;
`

export const GenerateAPIKeyFooter = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 16px 0 0 0;
`

export const FooterCloseButton = styled(SoftButton)`
  color: #dff7c7;
`

// Delete key Dialog

export const DeleteConfirmDialog = styled(Dialog)`
  top: -30%;
`

export const DeleteConfirmBody = styled(Box)`
  padding: 8px;
  width: 460px;
`

export const DialogBodyContent = styled(DialogContent)`
  background-color: #121212;
`

export const DialogSubtitle = styled(DialogContentText)`
  color: #d9d9d9;
  margin-top: 16px;
`

export const CancelButton = styled(Button)`
  background-color: #595959;
  width: 100px;
  margin-right: 4px;
  color: #f6f6f6;

  &:hover {
    background-color: #595959;
  }
`

export const DeleteConfirmButton = styled(LoadingButton)`
  color: #fca5a5;
  background-color: #333333;
  padding: 6px 16px;

  &:hover {
    background-color: #333333;
  }
`

export const DeleteDialogFooter = styled(DialogActions)`
  background-color: #121212;
`
