import { t, Trans } from "@lingui/macro"
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material"
import { useFormik } from "formik"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { object, string } from "yup"

import type { SubscriptionProfile } from "../../../../contexts/ProfileInfoContext"
import { useProfileInfo } from "../../../../contexts/ProfileInfoContext"

import {
  FormTwoRowContent,
  InfoFooterLayout,
  InfoFooterText,
  InfoLayout,
  LoaderFooterButton,
  ProfileInfoFooter,
} from "./ProfileInfo.styles"

const teamSizeOptions = [
  {
    name: "Solo Developer",
  },
  {
    name: "Small Team",
    size: "2 - 5",
  },
  {
    name: "Indie Studio",
    size: "6 - 24",
  },
  {
    name: "Large Studio",
    size: "25+",
  },
]

const web3ExperienceOptions = ["Beginner", "Intermediate", "Advanced"]
const profileInfoSchema = object().shape({
  profileFirstName: string().trim().required("First name is required"),
  profileLastName: string().trim().required("Last name is required"),
  profileDiscordUsername: string().trim().required("Discord username is required"),
  teamSize: string().trim().required("Team size is required"),
  companyName: string().trim().required("Company name is required"),
  companyUrl: string()
    .trim()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Please enter a valid URL"
    )
    .required("Company URL is required"),
  web3Experience: string().trim().required("Web3 experience is required"),
})

const ProfileInfo: React.FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { setProfileInfo, userLocalStore, updateSubscriptionProfile } = useProfileInfo()
  const [hasInfoChanged, setHasInfoChanged] = useState<boolean>()

  const profileInfoFormik = useFormik<SubscriptionProfile>({
    initialValues: {
      profileFirstName: userLocalStore?.["csgd.profileFirstName"] || "",
      profileLastName: userLocalStore?.["csgd.profileLastName"] || "",
      profileDiscordUsername: userLocalStore?.["csgd.profileDiscordUsername"] || "",
      teamSize: userLocalStore?.["csgd.teamSize"] || "",
      companyName: userLocalStore?.["csgd.companyName"] || "",
      companyUrl: userLocalStore?.["csgd.companyUrl"] || "",
      web3Experience: userLocalStore?.["csgd.web3Experience"] || "",
    },
    validationSchema: profileInfoSchema,
    validateOnChange: false,
    enableReinitialize: false,
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true)
        // update profile in storage
        await setProfileInfo({
          "csgd.profileFirstName": values.profileFirstName.trim(),
          "csgd.profileLastName": values.profileLastName.trim(),
          "csgd.profileDiscordUsername": values.profileDiscordUsername.trim(),
          "csgd.teamSize": values.teamSize.trim(),
          "csgd.companyName": values.companyName.trim(),
          "csgd.companyUrl": values.companyUrl.trim(),
          "csgd.web3Experience": values.web3Experience.trim(),
        })
        // update subscription profile in game API
        await updateSubscriptionProfile(values)

        helpers.setSubmitting(false)
        enqueueSnackbar(t`Profile information has been updated`, {
          variant: "success",
        })
      } catch (error: any) {
        helpers.setSubmitting(false)
        console.error(error)
        enqueueSnackbar(
          error.response || error.message || t`Failed to update profile information`,
          {
            variant: "error",
          }
        )
      }
    },
  })

  const { setValues } = profileInfoFormik

  // setting values of user store read
  useEffect(() => {
    setValues({
      profileFirstName: userLocalStore?.["csgd.profileFirstName"] || "",
      profileLastName: userLocalStore?.["csgd.profileLastName"] || "",
      profileDiscordUsername: userLocalStore?.["csgd.profileDiscordUsername"] || "",
      teamSize: userLocalStore?.["csgd.teamSize"] || "",
      companyName: userLocalStore?.["csgd.companyName"] || "",
      companyUrl: userLocalStore?.["csgd.companyUrl"] || "",
      web3Experience: userLocalStore?.["csgd.web3Experience"] || "",
    })
    setHasInfoChanged(false)
  }, [userLocalStore, setValues])

  return (
    <form onSubmit={profileInfoFormik.handleSubmit}>
      <InfoLayout edit={String(isEdit)}>
        <FormTwoRowContent>
          <TextField
            fullWidth
            id="profileFirstName"
            name="profileFirstName"
            data-cy="input-profile-first-name"
            label={t`First Name`}
            size="small"
            placeholder={t`First Name`}
            value={profileInfoFormik.values.profileFirstName}
            onChange={(e) => {
              profileInfoFormik.setFieldValue("profileFirstName", e.target.value)
              setHasInfoChanged(true)
            }}
            error={
              profileInfoFormik.touched.profileFirstName &&
              Boolean(profileInfoFormik.errors.profileFirstName)
            }
            helperText={profileInfoFormik.errors.profileFirstName}
          />
          <TextField
            fullWidth
            id="profileLastName"
            name="profileLastName"
            data-cy="input-profile-last-name"
            label={t`Last Name`}
            size="small"
            placeholder={t`Last Name`}
            value={profileInfoFormik.values.profileLastName}
            onChange={(e) => {
              profileInfoFormik.setFieldValue("profileLastName", e.target.value)
              setHasInfoChanged(true)
            }}
            error={
              profileInfoFormik.touched.profileLastName &&
              Boolean(profileInfoFormik.errors.profileLastName)
            }
            helperText={profileInfoFormik.errors.profileLastName}
          />
        </FormTwoRowContent>

        <TextField
          fullWidth
          id="profileDiscordUsername"
          name="profileDiscordUsername"
          data-cy="input-profile-discord-username"
          label={t`Discord Username`}
          size="small"
          placeholder={t`Discord Username`}
          value={profileInfoFormik.values.profileDiscordUsername}
          onChange={(e) => {
            profileInfoFormik.setFieldValue("profileDiscordUsername", e.target.value)
            setHasInfoChanged(true)
          }}
          error={
            profileInfoFormik.touched.profileDiscordUsername &&
            Boolean(profileInfoFormik.errors.profileDiscordUsername)
          }
          helperText={profileInfoFormik.errors.profileDiscordUsername}
        />

        <TextField
          fullWidth
          id="companyName"
          name="companyName"
          data-cy="input-profile-company-name"
          label={t`Company/Studio Name`}
          size="small"
          placeholder={t`Company/Studio Name`}
          value={profileInfoFormik.values.companyName}
          onChange={(e) => {
            profileInfoFormik.setFieldValue("companyName", e.target.value)
            setHasInfoChanged(true)
          }}
          error={
            profileInfoFormik.touched.companyName && Boolean(profileInfoFormik.errors.companyName)
          }
          helperText={profileInfoFormik.errors.companyName}
        />
        <TextField
          fullWidth
          id="companyUrl"
          name="companyUrl"
          data-cy="input-profile-company-url"
          label={t`Company/Studio URL`}
          size="small"
          placeholder={t`Company/Studio URL`}
          value={profileInfoFormik.values.companyUrl}
          onChange={(e) => {
            profileInfoFormik.setFieldValue("companyUrl", e.target.value)
            setHasInfoChanged(true)
          }}
          error={
            profileInfoFormik.touched.companyUrl && Boolean(profileInfoFormik.errors.companyUrl)
          }
          helperText={profileInfoFormik.errors.companyUrl}
        />

        <FormTwoRowContent>
          <FormControl fullWidth size="small">
            <InputLabel id="teamSize">
              <Trans>Team Size</Trans>
            </InputLabel>
            <Select
              id="teamSize"
              data-cy="dropdown-profile-team-size"
              value={profileInfoFormik.values.teamSize}
              label={t`Team Size`}
              size="small"
              onChange={(e) => {
                profileInfoFormik.setFieldValue("teamSize", e.target.value || "")
                setHasInfoChanged(true)
              }}
              error={
                profileInfoFormik.touched.teamSize && Boolean(profileInfoFormik.errors.teamSize)
              }
            >
              {teamSizeOptions.map((teamSizeOption) => (
                <MenuItem
                  data-cy={`dropdown-team-size-option-${teamSizeOption.name.toLowerCase().replaceAll(" ", "-")}`}
                  key={teamSizeOption.name}
                  value={teamSizeOption.name}
                >
                  {teamSizeOption.name} &nbsp;
                  {!!teamSizeOption.size && (
                    <span style={{ color: "GrayText" }}>({teamSizeOption.size})</span>
                  )}
                </MenuItem>
              ))}
            </Select>
            {profileInfoFormik.errors.teamSize ? (
              <FormHelperText data-cy="label-error-team-size" error>
                {profileInfoFormik.errors.teamSize}
              </FormHelperText>
            ) : null}
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel id="web3Experience">
              <Trans>Blockchain/Web3 Experience</Trans>
            </InputLabel>
            <Select
              id="web3Experience"
              data-cy="dropdown-profile-blockchain-experience"
              value={profileInfoFormik.values.web3Experience}
              label={t`Blockchain/Web3 Experience`}
              onChange={(e) => {
                profileInfoFormik.setFieldValue("web3Experience", e.target.value || "")
                setHasInfoChanged(true)
              }}
              error={
                profileInfoFormik.touched.web3Experience &&
                Boolean(profileInfoFormik.errors.web3Experience)
              }
            >
              {web3ExperienceOptions.map((w) => (
                <MenuItem
                  data-cy={`dropdown-blockchain-experience-option-${w.toLowerCase()}`}
                  key={w}
                  value={w}
                >
                  {w}
                </MenuItem>
              ))}
            </Select>
            {profileInfoFormik.errors.web3Experience ? (
              <FormHelperText data-cy="label-error-blockchain-experience" error>
                {profileInfoFormik.errors.web3Experience}
              </FormHelperText>
            ) : null}
          </FormControl>
        </FormTwoRowContent>
      </InfoLayout>
      <ProfileInfoFooter edit={String(isEdit)}>
        <LoaderFooterButton
          color="primary"
          variant="contained"
          type="submit"
          data-cy="button-profile-submit"
          loading={profileInfoFormik.isSubmitting}
          disabled={isEdit ? !hasInfoChanged : false}
          edit={String(isEdit)}
        >
          {isEdit ? <Trans>Save Changes</Trans> : <Trans>Finish Setup</Trans>}
        </LoaderFooterButton>
      </ProfileInfoFooter>

      {!isEdit ? (
        <InfoFooterLayout>
          <InfoFooterText variant="body2">
            <Trans>
              *By signing up, you will be subscribed to communications from our product team, the
              monthly Gaming community newsletter, and critical security and functionality update
              emails. You may unsubscribe at any time.
            </Trans>
          </InfoFooterText>
        </InfoFooterLayout>
      ) : null}
    </form>
  )
}

export default ProfileInfo
