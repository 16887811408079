import { t, Trans } from "@lingui/macro"
import { CircularProgress, Modal, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import { useState } from "react"
import type { FC } from "react"

import type { AccessKey } from "@chainsafe/files-api-client"
import {
  APISecretBox,
  FooterCloseButton,
  GenerateAPIKeyBody,
  GenerateAPIKeyBoxTitle,
  GenerateAPIKeyFooter,
  GenerateAPIKeyInfoBox,
  GenerateAPIKeyLoaderContainer,
  SecretCopyButton,
  SecretText,
  SeeToggleButton,
} from "./ApiKeys.styles"

type TProps = {
  isAPIKeyModalOpen: boolean
  generatedAPIKey?: AccessKey
  closeGenerateAPIKeyModal: () => void
}

const GenerateApiKeyModal: FC<TProps> = ({
  isAPIKeyModalOpen,
  generatedAPIKey,
  closeGenerateAPIKeyModal,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const [isShowingFullSecret, setIsShowingFullSecret] = useState(false)

  return (
    <Modal open={isAPIKeyModalOpen}>
      <GenerateAPIKeyBody>
        <Typography variant="h6">
          <Trans>Generate API Key</Trans>
        </Typography>
        {!generatedAPIKey ? (
          <GenerateAPIKeyLoaderContainer>
            <CircularProgress />
          </GenerateAPIKeyLoaderContainer>
        ) : (
          <>
            <GenerateAPIKeyInfoBox>
              <GenerateAPIKeyBoxTitle variant="body2">
                <Trans>Key ID</Trans>
              </GenerateAPIKeyBoxTitle>
              <Typography>{generatedAPIKey.id}</Typography>
            </GenerateAPIKeyInfoBox>
            <GenerateAPIKeyInfoBox>
              <GenerateAPIKeyBoxTitle variant="body2">
                <Trans>Secret</Trans>
              </GenerateAPIKeyBoxTitle>
              <Typography variant="body1" marginBottom="8px">
                Remember to securely store this secret phrase, since it appears only this one time.
              </Typography>
              <APISecretBox>
                <SecretText singleLine={!isShowingFullSecret}>
                  {generatedAPIKey.secret}
                  <br />
                  <SeeToggleButton onClick={() => setIsShowingFullSecret((prev) => !prev)}>
                    {isShowingFullSecret ? <Trans>see less</Trans> : <Trans>see more</Trans>}
                  </SeeToggleButton>
                </SecretText>
                <SecretCopyButton
                  onClick={() => {
                    navigator.clipboard.writeText(generatedAPIKey.secret)
                    enqueueSnackbar(t`API Key secret copied`, { variant: "success" })
                  }}
                >
                  <Trans>Copy</Trans>
                </SecretCopyButton>
              </APISecretBox>
            </GenerateAPIKeyInfoBox>
            <GenerateAPIKeyFooter>
              <FooterCloseButton onClick={closeGenerateAPIKeyModal}>
                <Trans>Close</Trans>
              </FooterCloseButton>
            </GenerateAPIKeyFooter>
          </>
        )}
      </GenerateAPIKeyBody>
    </Modal>
  )
}

export default GenerateApiKeyModal
