import { Box, Button, Typography } from "@mui/material"
import { ethers } from "ethers"
import React, { useMemo } from "react"

import type { MarketplaceItemListings } from "../../../../contexts/MarketplaceContext"
import { useNFTs } from "../../../../contexts/NFTsContext"
import { useWeb3Connection } from "../../../../contexts/Web3ConnectionContext"
import { capitalize, getIPFSUri } from "../../../../utils/Helpers"

interface IProps {
  NFTItem: MarketplaceItemListings
}

const NFTItemCard: React.FC<IProps> = ({ NFTItem }) => {
  const { getNetworkSymbol } = useWeb3Connection()
  const { openNFTModal } = useNFTs()

  const { token, chain_id, status, listings, deadline, minimumPriceListing } = NFTItem
  const { metadata, token_id } = token

  const symbol = useMemo(() => getNetworkSymbol(chain_id), [chain_id, getNetworkSymbol])

  if (!metadata) return null

  const { image, name } = metadata

  return (
    <>
      <Box
        width="100%"
        sx={{
          borderRadius: "12px",
          background: "#262626",
        }}
      >
        <Box
          height={180}
          display="flex"
          alignItems="center"
          sx={{ background: "#141414", borderRadius: "12px" }}
        >
          {image ? (
            <img
              style={{ width: "100%", maxHeight: 180, borderRadius: "12px", objectFit: "contain" }}
              src={getIPFSUri(image)}
              alt="collection"
              data-cy="label-listed-nft-card-image"
            />
          ) : (
            <Box height="180px" width="100%" sx={{ background: "#595959", borderRadius: "12px" }} />
          )}
        </Box>
        <Box p={1} mt={0.5}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography fontSize="18px" data-cy="label-listed-nft-card-name">
              {name}
            </Typography>

            <Button
              variant="text"
              sx={{
                color: "#E8E8E8",
                fontWeight: 200,
                textDecoration: "underline",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() =>
                openNFTModal({
                  contract_address: token.contract_address,
                  token_id: token.token_id,
                  token_type: token.token_type,
                  uri: token.uri,
                  metadata: token.metadata,
                  listings: listings,
                  deadline: deadline,
                  supply: token.metadata?.tokenType?.supply,
                  chain_id,
                })
              }
              data-cy="button-listed-nft-card-details"
            >
              Details
            </Button>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box sx={{ width: 80 }}>
              <Typography color="#8C8C8C" fontSize={14}>
                Price
              </Typography>
              <Typography data-cy="label-listed-nft-card-price">
                {minimumPriceListing && ethers.utils.formatEther(minimumPriceListing)} {symbol}
              </Typography>
            </Box>
            <Box sx={{ width: 80 }}>
              <Typography color="#8C8C8C" fontSize={14}>
                Type
              </Typography>
              <Typography>{capitalize(token.token_type)}</Typography>
            </Box>
            <Box sx={{ width: 80 }}>
              <Typography color="#8C8C8C" fontSize={14}>
                Status
              </Typography>
              <Typography data-cy="label-listed-nft-card-status">{capitalize(status)}</Typography>
            </Box>
            <Box sx={{ width: 80 }}>
              <Typography color="#8C8C8C" fontSize={14}>
                ID
              </Typography>
              <Typography data-cy="label-listed-nft-card-id">#{token_id}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default NFTItemCard
