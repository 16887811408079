import { Trans } from "@lingui/macro"
import { Modal } from "@mui/material"
import React from "react"

import ProfileInfo from "./ProfileInfo"
import {
  ProfileInfoHeading,
  ProfileInfoModalBox,
  ProfileInfoSubHeading,
} from "./ProfileInfo.styles"

const ProfileInfoModal: React.FC = () => {
  return (
    <Modal open={true}>
      <ProfileInfoModalBox>
        <ProfileInfoHeading variant="h4">
          <Trans>Say hello to ChainSafe Gaming!</Trans>
        </ProfileInfoHeading>
        <ProfileInfoSubHeading variant="body1">
          <Trans>
            Fill out the below information to generate a project ID and register your game in
            Web3.Unity.
          </Trans>
        </ProfileInfoSubHeading>
        <ProfileInfo />
      </ProfileInfoModalBox>
    </Modal>
  )
}

export default ProfileInfoModal
