import { t, Trans } from "@lingui/macro"
import { Typography } from "@mui/material"
import { useState } from "react"

import { SoftButton } from "../../../../elements/SoftButton"

import {
  AddBundleContainer,
  AddNewBundleButton,
  CancelButton,
  Erc20RewardAddressContainer,
  Erc20RewardInputContainer,
  RewardInputs,
  RewardUnitAddress,
  RewardUnitsBody,
  RewardUnitsContainer,
  RewardUnitsHeading,
  RewardUnitsRow,
  SaveBundlesContainer,
} from "./styles/RewardUnits.styles"
import type { ILootboxErc20Bundle, ILootboxERC20Reward } from "./types"

interface INewLootboxErc20Bundle extends ILootboxErc20Bundle {
  id: number
}

interface IProps {
  erc20LootboxReward: ILootboxERC20Reward
  handleErc20RewardsSubmit: (
    tokenAddress: string,
    tokens: string[],
    bundles: string[]
  ) => Promise<boolean | undefined>
}

const ERC20RewardBox: React.FC<IProps> = ({ erc20LootboxReward, handleErc20RewardsSubmit }) => {
  const [oldBundles, setOldBundles] = useState<ILootboxErc20Bundle[]>([
    ...erc20LootboxReward.bundles,
  ])
  const [hasErc20RewardChanged, setHasErc20RewardChanged] = useState(false)

  const [newBundles, setNewBundles] = useState<INewLootboxErc20Bundle[]>([])
  const [isRewardsUpdating, setIsRewardsUpdating] = useState(false)

  const updateERC20Rewards = async (): Promise<void> => {
    setIsRewardsUpdating(true)

    handleErc20RewardsSubmit(
      erc20LootboxReward.tokenAddress,
      [...oldBundles, ...newBundles].map((bundle) => bundle.numberOfTokens),
      [...oldBundles, ...newBundles].map((bundle) => bundle.numberOfBundles)
    )
      .catch()
      .finally(() => {
        setIsRewardsUpdating(false)
      })
  }

  const onCancel = (): void => {
    setNewBundles([])
    setOldBundles([...erc20LootboxReward.bundles])
    setHasErc20RewardChanged(false)
  }

  const updateOldBundle = (bundle: string, index: number): void => {
    setOldBundles((prevState) => [
      ...prevState.map((oldBundle, i) =>
        i === index
          ? {
              numberOfTokens: oldBundle.numberOfTokens,
              numberOfBundles: bundle,
            }
          : oldBundle
      ),
    ])
    setHasErc20RewardChanged(true)
  }

  const updateNewBundleTokens = (token: string, bundle: INewLootboxErc20Bundle): void => {
    setNewBundles((prevState) => [
      ...prevState.map((newBundle) =>
        bundle.id === newBundle.id
          ? {
              ...newBundle,
              numberOfTokens: token,
            }
          : newBundle
      ),
    ])
    setHasErc20RewardChanged(true)
  }

  const updateNewBundleBundle = (bundle: string, newBundle: INewLootboxErc20Bundle): void => {
    setNewBundles((prevState) => [
      ...prevState.map((prevNewBundle) =>
        newBundle.id === prevNewBundle.id
          ? {
              ...prevNewBundle,
              numberOfBundles: bundle,
            }
          : prevNewBundle
      ),
    ])
    setHasErc20RewardChanged(true)
  }

  const addNewBundle = (): void => {
    setNewBundles((prevState) => [
      ...newBundles,
      {
        id: prevState.length ? prevState[prevState.length - 1].id + 1 : 1,
        numberOfTokens: "",
        numberOfBundles: "",
      },
    ])
    setHasErc20RewardChanged(true)
  }

  return (
    <RewardUnitsBody>
      <RewardUnitsContainer>
        <RewardUnitsHeading>
          <Typography width="60%">
            <Trans>Loot token contracts</Trans>
          </Typography>
          <Typography width="20%" align="center">
            <Trans># of tokens</Trans>
          </Typography>
          <Typography width="20%" align="center">
            <Trans># of bundles</Trans>
          </Typography>
        </RewardUnitsHeading>
        <RewardUnitsRow>
          <Erc20RewardAddressContainer>
            <RewardUnitAddress>{erc20LootboxReward.tokenAddress}</RewardUnitAddress>
          </Erc20RewardAddressContainer>
          <Erc20RewardInputContainer />
          <Erc20RewardInputContainer />
        </RewardUnitsRow>
        {oldBundles.map((bundle, index) => (
          <RewardUnitsRow key={bundle.numberOfTokens}>
            <Erc20RewardAddressContainer>
              <RewardUnitAddress>Bundle {index + 1}</RewardUnitAddress>
            </Erc20RewardAddressContainer>
            <Erc20RewardInputContainer>
              <Typography>{bundle.numberOfTokens}</Typography>
            </Erc20RewardInputContainer>
            <Erc20RewardInputContainer>
              <RewardInputs
                fullWidth
                size="small"
                placeholder={t`# bundles`}
                value={bundle.numberOfBundles}
                onChange={(e) => {
                  updateOldBundle(e.target.value, index)
                }}
              />
            </Erc20RewardInputContainer>
          </RewardUnitsRow>
        ))}
        {newBundles.map((newBundle, index) => (
          <RewardUnitsRow key={newBundle.id}>
            <Erc20RewardAddressContainer>
              <RewardUnitAddress>Bundle {index + oldBundles.length + 1}</RewardUnitAddress>
            </Erc20RewardAddressContainer>
            <Erc20RewardInputContainer>
              <RewardInputs
                fullWidth
                size="small"
                placeholder={t`# tokens`}
                value={newBundle.numberOfTokens}
                onChange={(e) => {
                  updateNewBundleTokens(e.target.value, newBundle)
                }}
              />
            </Erc20RewardInputContainer>
            <Erc20RewardInputContainer>
              <RewardInputs
                fullWidth
                size="small"
                placeholder={t`# bundles`}
                value={newBundle.numberOfBundles}
                onChange={(e) => {
                  updateNewBundleBundle(e.target.value, newBundle)
                }}
              />
            </Erc20RewardInputContainer>
          </RewardUnitsRow>
        ))}
        <AddBundleContainer>
          <AddNewBundleButton variant="text" onClick={addNewBundle}>
            + Add new bundle
          </AddNewBundleButton>
        </AddBundleContainer>
        <SaveBundlesContainer>
          <CancelButton disabled={!hasErc20RewardChanged || isRewardsUpdating} onClick={onCancel}>
            Cancel
          </CancelButton>
          <SoftButton
            disabled={!hasErc20RewardChanged || isRewardsUpdating}
            loading={isRewardsUpdating}
            onClick={updateERC20Rewards}
          >
            Save changes
          </SoftButton>
        </SaveBundlesContainer>
      </RewardUnitsContainer>
    </RewardUnitsBody>
  )
}

export default ERC20RewardBox
