import { Trans } from "@lingui/macro"
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { useConnectWallet, useSetChain } from "@web3-onboard/react"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useLootbox } from "../../../../contexts/LootboxContext"
import { TOKEN_TYPE } from "../../../../contexts/NFTsContext"
import type { IUploadNFTRequest } from "../../../../contexts/StorageContext"
import { useStorageApi } from "../../../../contexts/StorageContext"
import { useWeb3Connection } from "../../../../contexts/Web3ConnectionContext"
import { ROUTE_LINKS } from "../../../../routes/routes"
import { getIPFSUri } from "../../../../utils/Helpers"
import { initialNetworks } from "../../../../utils/NetworkHelpers"
import ImageInput from "../../../elements/ImageInput"
import { SoftButton } from "../../../elements/SoftButton"

const lootboxNetworks = initialNetworks.filter((n) => !!n.lootboxFactoryAddress)

const DeployLootbox: React.FC = () => {
  const { isLoggedIn, network } = useWeb3Connection()
  const [, setChain] = useSetChain()
  const { enqueueSnackbar } = useSnackbar()
  const { uploadNFTData } = useStorageApi()
  const { deployLootbox } = useLootbox()

  const [, connect] = useConnectWallet()
  const navigate = useNavigate()
  const [isLoadingDeployLootbox, setIsLoadingDeployLootbox] = useState(false)

  const [bannerImages, setBannerImages] = useState<File[]>([])

  const [lootboxName, setLootboxName] = useState("")
  const [lootboxDescription, setLootboxDescription] = useState("")
  const [lootboxChainId, setLootboxChainId] = useState<number>(1)

  useEffect(() => {
    if (network) {
      setLootboxChainId(lootboxNetworks.find((lN) => lN.chainId === network.chainId)?.chainId || 1)
    }
  }, [network])

  const onChangeLootboxChain = async (chainId: number): Promise<void> => {
    if (isLoggedIn) {
      const isSwitched = await setChain({ chainId: `0x${chainId.toString(16)}` })
      if (isSwitched) setLootboxChainId(chainId)
    }
  }

  const onDeployLootbox = async (): Promise<void> => {
    // initial validation
    if (!lootboxName.trim() || !bannerImages.length) {
      enqueueSnackbar("Please provide LootBox name and Cover media", { variant: "error" })
      return
    }

    setIsLoadingDeployLootbox(true)

    const NFTRequest: IUploadNFTRequest = {
      name: lootboxName.trim(),
      image: bannerImages[0],
      description: lootboxDescription.trim(),
      tokenType: TOKEN_TYPE["ERC1155"],
      additionalFiles: [],
    }

    // upload NFT to storage
    uploadNFTData(NFTRequest)
      .then(async (cid) => {
        if (!cid) {
          setIsLoadingDeployLootbox(false)
          enqueueSnackbar("Failed to deploy LootBox data", {
            variant: "error",
            id: "toast-storage-error-deploy-lootbox",
          })
          return
        }

        // we have CID from storage
        const tokenUri = getIPFSUri(cid)

        deployLootbox(tokenUri)
          .then(() => {
            setIsLoadingDeployLootbox(false)

            enqueueSnackbar("LootBox successfully deployed", {
              variant: "success",
              autoHideDuration: 6000,
              id: "toast-success-deploy-lootbox",
            })

            // navigate to lootboxes page
            navigate(ROUTE_LINKS.Lootboxes)
          })
          .catch((err) => {
            console.error(err)
            enqueueSnackbar("Failed to deploy LootBox", {
              variant: "error",
              id: "toast-error-deploy-lootbox",
            })
            setIsLoadingDeployLootbox(false)
          })
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar("Failed to upload LootBox data", {
          variant: "error",
          id: "toast-upload-data-error-deploy-lootbox",
        })
        setIsLoadingDeployLootbox(false)
      })
  }

  return (
    <Box display="flex" justifyContent="center" mt={4} mb={16}>
      <Box
        width={960}
        sx={{ borderRadius: "8px", border: "1px solid #262626", background: "#141414" }}
      >
        <Box p={4} pb={0}>
          <Typography fontSize={28}>
            <Trans>New LootBox</Trans>
          </Typography>
        </Box>
        <Box p={4}>
          <Typography color="#DFF7C7">
            <Trans>Basic Information</Trans>
          </Typography>
          <Box display="flex" flexDirection="column" gap={2} mt={3}>
            <Box display="grid" gap={2} sx={{ gridTemplateColumns: "1fr 1fr" }}>
              <TextField
                label="LootBox Name"
                size="small"
                value={lootboxName}
                onChange={(e) => setLootboxName(e.target.value)}
                inputProps={{ maxLength: 100 }}
                data-cy="input-new-lootbox-name"
              />
              <FormControl size="small">
                <InputLabel>Network</InputLabel>
                <Select
                  label="network"
                  size="small"
                  defaultValue={lootboxChainId}
                  value={lootboxChainId}
                  onChange={(e) => onChangeLootboxChain(e.target.value as number)}
                  data-cy="dropdown-new-lootbox-network"
                >
                  {lootboxNetworks.map((n) => (
                    <MenuItem key={n.chainId} value={n.chainId}>
                      {n.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <TextField
              label="Description (Optional)"
              multiline
              rows={2}
              size="small"
              inputProps={{ maxLength: 2000 }}
              value={lootboxDescription}
              onChange={(e) => setLootboxDescription(e.target.value)}
              data-cy="input-new-lootbox-description"
            />
          </Box>
        </Box>
        <Box pl={4} pr={4} mb={4}>
          <Typography color="#DFF7C7" sx={{ mb: 1.5 }}>
            <Trans>LootBox Media</Trans>
          </Typography>
          <Typography fontSize="13px" sx={{ mb: 1 }}>
            <Trans>Cover Image (recommended min. 640 x 240 px)</Trans>
          </Typography>
          <ImageInput images={bannerImages} setImages={setBannerImages} testId="new-lootbox" />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={3} pr={4} gap={2}>
          <Button
            onClick={() => {
              navigate(ROUTE_LINKS.Lootboxes)
            }}
            sx={{ background: "#262626", color: "#FFFFFF", pl: 2, pr: 2 }}
            data-cy="button-new-lootbox-cancel"
          >
            <Trans>Cancel</Trans>
          </Button>
          {isLoggedIn ? (
            <SoftButton
              loading={isLoadingDeployLootbox}
              disabled={!lootboxName.trim() || !bannerImages.length}
              onClick={onDeployLootbox}
              data-cy="button-new-lootbox-deploy"
            >
              <Trans>Deploy LootBox</Trans>
            </SoftButton>
          ) : (
            <SoftButton onClick={() => connect()} data-cy="button-new-lootbox-connect-wallet">
              <Trans>Connect wallet</Trans>
            </SoftButton>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default DeployLootbox
