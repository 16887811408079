import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { styled, Box, Typography, TextField, Button } from "@mui/material"

import { SoftButton } from "../../../../../elements/SoftButton"

export const LoadingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`

export const RewardUnitsLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const RewardUnitsBox = styled(Box)`
  background-color: #141414;
  border: 1px solid #262626;
  border-radius: 8px;
`

export const RewardUnitsBody = styled(Box)`
  border-top: 1px solid #262626;
  border-bottom: 1px solid #262626;
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const RewardUnitsContainer = styled(Box)`
  border: 1px solid #262626;
  border-radius: 8px;
`

export const RewardUnitsHeading = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  background: rgba(38, 38, 38, 1);
  padding: 12px 24px;
`

export const RewardUnitsRow = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid #262626;
`

export const RewardUnitAddressContainer = styled(Box)`
  width: 60%;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const RewardRemainingBalanceText = styled(Typography)`
  color: rgb(140, 140, 140);
`

export const RewardUnitAddress = styled(Typography)`
  max-width: 4200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const RewardUnitInputContainer = styled(Box)`
  width: 20%;
  padding: 0 16px;
  display: flex;
  justify-content: center;
`

export const RewardTypeText = styled(Typography)`
  max-width: 100px;
  color: rgb(140, 140, 140);
`

export const RewardInputs = styled(TextField)`
  max-width: 100px;

  input {
    text-align: center;
  }
`

export const NoRewardUnitsText = styled(Typography)`
  margin: 24px;
  color: #bfbfbf;
`

export const RewardUnitsFooter = styled(Box)`
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #262626;
`

// ERC20 Modal styles

export const HeadingSection = styled(Box)`
  padding: 24px 24px 12px;
  border-bottom: 1px solid #333333;

  h5 {
    padding-bottom: 16px;
  }
`

export const StepsSection = styled(Box)`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 8px;
`

export const StepText = styled(Typography)<{ active?: string }>`
  font-size: 14px;
  color: ${({ active }) => (active === "true" ? "#FFF" : "#8C8C8C")};
`

export const AllStepsContainer = styled(Box)`
  max-height: 400px;
  overflow-y: scroll;
`

export const StepContainer = styled(Box)<{ active?: string }>`
  background-color: #333333;
  margin: 0 24px;
  border-radius: 12px;
  opacity: ${({ active }) => (active === "true" ? 1 : 0.2)};
  margin-bottom: 8px;
  padding: 16px;
`

export const Footer = styled(Box)`
  border-top: 1px solid #333333;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`

export const StepFooter = styled(Box)`
  display: flex;
  justify-content: flex-end;
`

export const StepBody = styled(Box)`
  padding: 16px 0;
`

export const ActionButton = styled(SoftButton)(() => ({
  backgroundColor: "#595959",
  color: "#dff7c7",
  "&:hover": {
    backgroundColor: "#595959",
  },
}))

export const BundleContainer = styled(Box)`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  align-items: center;
`

export const AddBundleButton = styled(Button)(() => ({
  fontSize: "14px",
  color: "#dff7c7",
}))

export const CrossButton = styled(CloseOutlinedIcon)`
  font-size: 14px;
  cursor: pointer;
  color: #8c8c8c;
`

export const CancelButton = styled(SoftButton)`
  color: #ffffff;
  border-color: #ffffff;
`
// ERC20 Box

export const NoRewardsBox = styled(Box)`
  border-top: 1px solid #262626;
  padding: 16px 32px;
`

export const Erc20RewardAddressContainer = styled(Box)`
  width: 60%;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Erc20RewardInputContainer = styled(Box)`
  width: 20%;
  padding: 0 16px;
  display: flex;
  justify-content: center;
`

export const AddBundleContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  padding: 8px 16px;
  border-bottom: 1px solid #262626;
`

export const AddNewBundleButton = styled(Button)(() => ({
  fontSize: "14px",
  color: "#dff7c7",
}))

export const SaveBundlesContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  gap: 16px;
`
