import type { AccessKey } from "@chainsafe/files-api-client"
import { t, Trans } from "@lingui/macro"
import { MoreHoriz } from "@mui/icons-material"
import { Typography } from "@mui/material"
import dayjs from "dayjs"
import { useSnackbar } from "notistack"
import type { FC, MouseEvent } from "react"
import { useCallback, useState } from "react"

import {
  APIColumnText,
  APIKeysTableRow,
  APIKeyText,
  CancelButton,
  CopyButton,
  DeleteConfirmBody,
  DeleteConfirmButton,
  DeleteConfirmDialog,
  DeleteDialogFooter,
  DeleteMenuButton,
  DialogBodyContent,
  DialogSubtitle,
  DropdownContainer,
  DropdownIcon,
  DropdownMenu,
} from "./ApiKeys.styles"

interface IProps {
  APIKey: AccessKey
  deleteAPIKey: (keyId: string) => Promise<void>
  isDeleteAPIKeyLoading: boolean
}

const ApiKeyRow: FC<IProps> = ({
  APIKey: { id, created_at },
  deleteAPIKey,
  isDeleteAPIKeyLoading,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  const [optionsAnchorEl, setOptionsAnchorEl] = useState<null | HTMLElement>(null)
  const isOptionsOpen = Boolean(optionsAnchorEl)

  const handleOptionsClick = useCallback((event: MouseEvent<HTMLElement>): void => {
    setOptionsAnchorEl(event.currentTarget)
  }, [])

  const handleOptionsClose = useCallback((): void => {
    setOptionsAnchorEl(null)
  }, [])

  const handleDeleteOpen = useCallback(() => {
    handleOptionsClose()
    setIsDeleteOpen(true)
  }, [handleOptionsClose])

  const handleDeleteClose = useCallback(() => {
    setIsDeleteOpen(false)
  }, [])

  const handleDeleteAPIKey = useCallback(async () => {
    await deleteAPIKey(id)
    handleDeleteClose()
  }, [deleteAPIKey, handleDeleteClose, id])

  return (
    <>
      <APIKeysTableRow>
        <APIKeyText variant="body1">{id}</APIKeyText>
        <APIColumnText variant="body1">
          {dayjs(created_at).format("DD MMM, YY hh:mm A")}
        </APIColumnText>
        <CopyButton
          variant="text"
          onClick={() => {
            navigator.clipboard.writeText(id)
            enqueueSnackbar(t`API Key ID copied`, { variant: "success" })
          }}
        >
          <Trans>Copy</Trans>
        </CopyButton>
        <DropdownContainer>
          <DropdownIcon
            aria-label="more"
            id="api-key-options"
            aria-controls={isOptionsOpen ? "api-key-menu" : undefined}
            aria-expanded={isOptionsOpen ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleOptionsClick}
          >
            <MoreHoriz />
          </DropdownIcon>
          <DropdownMenu
            id="api-key-menu"
            MenuListProps={{
              "aria-labelledby": "api-key-options",
            }}
            anchorEl={optionsAnchorEl}
            open={isOptionsOpen}
            onClose={handleOptionsClose}
          >
            <DeleteMenuButton key="delete" onClick={handleDeleteOpen}>
              <Trans>Delete key</Trans>
            </DeleteMenuButton>
          </DropdownMenu>
        </DropdownContainer>
      </APIKeysTableRow>
      {/* Confirm API key delete modal */}
      <DeleteConfirmDialog open={isDeleteOpen} onClose={handleDeleteClose}>
        <DeleteConfirmBody>
          <DialogBodyContent>
            <Typography variant="h5" align="left">
              <Trans>Delete API Key?</Trans>
            </Typography>
            <DialogSubtitle>
              <Trans>Removing this API key is a permanent action and cannot be undone.</Trans>
            </DialogSubtitle>
          </DialogBodyContent>
          <DeleteDialogFooter>
            <CancelButton
              onClick={handleDeleteClose}
              variant="text"
              disabled={isDeleteAPIKeyLoading}
            >
              <Trans>Cancel</Trans>
            </CancelButton>
            <DeleteConfirmButton
              color="primary"
              onClick={handleDeleteAPIKey}
              loading={isDeleteAPIKeyLoading}
              autoFocus
            >
              <Trans>Yes, delete it</Trans>
            </DeleteConfirmButton>
          </DeleteDialogFooter>
        </DeleteConfirmBody>
      </DeleteConfirmDialog>
    </>
  )
}

export default ApiKeyRow
