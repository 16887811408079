import { Trans } from "@lingui/macro"
import { Box, Typography } from "@mui/material"
import React from "react"

import { useWeb3Connection } from "../../../../contexts/Web3ConnectionContext"
import { TOKEN_TYPE_KEYS } from "../../../../types/lootbox"

import ERC20Reward from "./Rewards/ERC20Reward"
import RewardCard from "./Rewards/RewardCard"
import type { ILootboxToken, IRewardEventArg } from "./types"

interface IProps {
  lootboxTokens: ILootboxToken[]
  eventArgs: IRewardEventArg[]
}

const InMyWallet: React.FC<IProps> = ({ lootboxTokens, eventArgs }) => {
  const { address } = useWeb3Connection()

  const eventsWithTokenInfo = eventArgs.map((e) => ({
    opener: e.opener,
    amount: e.amount,
    token: e.token,
    tokenId: e.tokenId,
    tokenType: lootboxTokens.find((l) => l.tokenAddress === e.token)?.tokenType,
  }))

  return (
    <Box mt={2} width="100%">
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        pb={2}
        sx={{
          borderBottom: "1px solid #595959",
        }}
      >
        <Typography variant="h4" sx={{ mb: 1 }}>
          <Trans>In My Wallet</Trans>
        </Typography>
        <Typography>Wallet Address: {address}</Typography>
      </Box>
      <Box display="flex" gap={4} m={4} flexWrap="wrap" justifyContent="center">
        {eventsWithTokenInfo.map((e) =>
          e.tokenType && TOKEN_TYPE_KEYS.ERC20.some((tokenType) => tokenType === e.tokenType) ? (
            <ERC20Reward
              amount={e.amount}
              address={e.token}
              key={`${e.token}-${e.tokenId.toString()}`}
            />
          ) : (
            <RewardCard
              tokenType={e.tokenType || 2}
              tokenId={e.tokenId}
              address={e.token}
              amount={e.amount.toString()}
              key={`${e.token}-${e.tokenId.toString()}`}
            />
          )
        )}
      </Box>
    </Box>
  )
}

export default InMyWallet
