import { t } from "@lingui/macro"
import { BigNumber, ethers } from "ethers"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useState } from "react"

import { KECCAK_256_DO_NOT_UNWRAP, useLootbox } from "../../../../../../contexts/LootboxContext"
import { useWeb3Connection } from "../../../../../../contexts/Web3ConnectionContext"
import ERC1155Abi from "../../../../../../contracts/Generic/GenericERC1155.json"
import ERC20Abi from "../../../../../../contracts/Generic/GenericERC20.json"
import { TOKEN_TYPE_KEYS, type LootboxInventoryResult } from "../../../../../../types/lootbox"
import { truncateDecimals } from "../../../../../../utils/Helpers"
import type { ILootboxERC20Reward, ILootboxReward, RewardUnitsHook } from "../types"

export const useRewardUnits = (): RewardUnitsHook => {
  const { address, network, signer } = useWeb3Connection()
  const { lootboxToManage, lootboxInventory, updateRewards, updateERC20Distributions } =
    useLootbox()

  const [lootboxRewards, setLootboxRewards] = useState<ILootboxReward[]>([])
  const [erc20LootboxRewards, setErc20LootboxRewards] = useState<ILootboxERC20Reward[]>([])
  const [isRewardsProcessing, setIsRewardsProcessing] = useState(true)
  const [isRewardsLoading, setIsRewardsLoading] = useState(false)
  const [hasRewardsChanged, setHasRewardsChanged] = useState(false)
  const [isVarERC20ModalOpen, setIsVarERC20ModalOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const processERC20Reward = useCallback(
    async (
      reward: LootboxInventoryResult,
      leftOverResult?: LootboxInventoryResult
    ): Promise<ILootboxReward[]> => {
      const erc20Contract = new ethers.Contract(reward.rewardToken, ERC20Abi, signer)
      let decimals
      try {
        decimals = await erc20Contract.decimals()
      } catch (err) {
        decimals = 18
      }
      const amountPerUnit = parseFloat(
        truncateDecimals(ethers.utils.formatUnits(reward.amountPerUnit.toString(), decimals), 2)
      )
      let balance = parseFloat(
        truncateDecimals(ethers.utils.formatUnits(reward.balance.toString(), decimals), 1)
      )

      if (leftOverResult) {
        balance += parseFloat(
          truncateDecimals(ethers.utils.formatUnits(leftOverResult.balance.toString(), decimals), 1)
        )
      }

      return [
        {
          tokenAddress: reward.rewardToken,
          rewardType: reward.rewardType,
          amountPerUnit: amountPerUnit.toString(),
          remainingBalance: balance,
          decimals: decimals,
        },
      ]
    },
    [signer]
  )

  const processERC1155OrVariableERC20Reward = useCallback(
    async (
      reward: LootboxInventoryResult
    ): Promise<{ rewards: ILootboxReward[]; erc20Rewards: ILootboxERC20Reward[] }> => {
      const rewards: ILootboxReward[] = []
      const erc20Rewards: ILootboxERC20Reward[] = []

      let isERC20VariableDistribution = false
      const erc1155Contract = new ethers.Contract(reward.rewardToken, ERC1155Abi, signer)
      try {
        const doNotUnwrapResponse = await erc1155Contract.DO_NOT_UNWRAP()

        if (doNotUnwrapResponse === KECCAK_256_DO_NOT_UNWRAP) {
          isERC20VariableDistribution = true
        }
      } catch (error) {
        console.error(error)
        // no need to handles
        // DO_NOT_UNWRAP function is optional at contract
      }

      if (isERC20VariableDistribution) {
        // ERC20 variable distributions processing

        const erc20Address = (await erc1155Contract.underlying()) as string
        const erc20Contract = new ethers.Contract(erc20Address, ERC20Abi, signer)
        let decimals: number
        try {
          decimals = await erc20Contract.decimals()
        } catch (err) {
          decimals = 18
        }

        erc20Rewards.push({
          tokenAddress: erc20Address,
          wrapperAddress: reward.rewardToken,
          rewardType: reward.rewardType,
          remainingBalance: reward.balance.toNumber(),
          bundles: reward.extra
            .filter((itemExtra) => itemExtra.id !== undefined)
            .map((itemExtra) => ({
              numberOfTokens: truncateDecimals(
                ethers.utils.formatUnits(itemExtra.id?.toString() || "", decimals),
                2
              ),
              numberOfBundles: itemExtra.balance.toString(),
            })),
        })
      } else {
        // ERC1155 processing
        rewards.push(
          ...reward.extra
            .filter((itemExtra) => itemExtra.id !== undefined)
            .map((itemExtra) => ({
              tokenAddress: reward.rewardToken,
              rewardType: reward.rewardType,
              remainingBalance: itemExtra.balance.toNumber(),
              amountPerUnit: itemExtra.amountPerUnit.toString(),
              tokenId: itemExtra.id?.toNumber(),
            }))
        )
      }

      return {
        rewards,
        erc20Rewards,
      }
    },
    [signer]
  )

  const processLootboxInventory = useCallback(async (): Promise<{
    rewards: ILootboxReward[]
    erc20Rewards: ILootboxERC20Reward[]
  }> => {
    const rewards: ILootboxReward[] = []
    const erc20Rewards: ILootboxERC20Reward[] = []

    const allRewards = [
      ...(lootboxInventory?.result || []),
      ...(lootboxInventory?.leftoversResult?.filter(
        (leftoverResult) =>
          !lootboxInventory.result?.some(
            (result) => result.rewardToken === leftoverResult.rewardToken
          )
      ) || []),
    ]

    for (const reward of allRewards) {
      const leftOverResult = lootboxInventory?.leftoversResult?.find(
        (leftover) => leftover.rewardToken === reward.rewardToken
      )

      if (TOKEN_TYPE_KEYS.ERC20.some((tokenType) => tokenType === reward.rewardType)) {
        // ERC20 processing
        const processedERC20Reward = await processERC20Reward(reward, leftOverResult)
        rewards.push(...processedERC20Reward)
      } else if (
        TOKEN_TYPE_KEYS.ERC721.some((tokenType) => tokenType === reward.rewardType) ||
        TOKEN_TYPE_KEYS.ERC1155NFT.some((tokenType) => tokenType === reward.rewardType)
      ) {
        // ERC721 and ERC1155NFT processing
        rewards.push({
          tokenAddress: reward.rewardToken,
          rewardType: reward.rewardType,
          amountPerUnit: reward.amountPerUnit.toString(),
          remainingBalance: reward.extra?.length || 0,
        })
      } else {
        // ERC1155 or ERC20 variable distributions processing
        const { rewards: processedRewards, erc20Rewards: processedERC20Rewards } =
          await processERC1155OrVariableERC20Reward(reward)

        rewards.push(...processedRewards)
        erc20Rewards.push(...processedERC20Rewards)
      }
    }

    setLootboxRewards(rewards)
    setErc20LootboxRewards(erc20Rewards)

    return {
      rewards,
      erc20Rewards,
    }
  }, [lootboxInventory, processERC20Reward, processERC1155OrVariableERC20Reward])

  useEffect(() => {
    setIsRewardsProcessing(true)
    processLootboxInventory()
      .catch(console.error)
      .finally(() => {
        setIsRewardsProcessing(false)
      })
  }, [processLootboxInventory])

  const updateLootboxRewards = useCallback(async () => {
    if (!hasRewardsChanged) return

    // validate amounts per unit
    if (
      lootboxRewards.some((reward) => {
        const amount = TOKEN_TYPE_KEYS.ERC20.some((tokenType) => tokenType === reward.rewardType)
          ? parseFloat(reward.amountPerUnit)
          : parseInt(reward.amountPerUnit)
        return Number.isNaN(amount) || amount < 0
      })
    ) {
      enqueueSnackbar("Number of rewards must be valid", {
        variant: "error",
        autoHideDuration: 5000,
      })
      return
    }

    // validate amounts per unit must be 1 or 0 for 721 and 1155NFT
    if (
      lootboxRewards.some(
        (reward) =>
          [...TOKEN_TYPE_KEYS.ERC721, ...TOKEN_TYPE_KEYS.ERC1155NFT].some(
            (tokenType) => tokenType === reward.rewardType
          ) && parseInt(reward.amountPerUnit) > 1
      )
    ) {
      enqueueSnackbar("Number of rewards for ERC721 and ERC1155NFT cannot be more than 1", {
        variant: "error",
        autoHideDuration: 5000,
      })
      return
    }

    const amountsPerUnit = lootboxRewards.map((reward) =>
      TOKEN_TYPE_KEYS.ERC20.some((tokenType) => tokenType === reward.rewardType)
        ? ethers.utils.parseUnits(reward.amountPerUnit, reward.decimals ?? 18)
        : BigNumber.from(parseInt(reward.amountPerUnit))
    )

    setIsRewardsLoading(true)

    try {
      await updateRewards(
        lootboxRewards.map((reward) => reward.tokenAddress),
        lootboxRewards.map((reward) => reward.tokenId ?? 0),
        amountsPerUnit
      )

      enqueueSnackbar(t`LootBox rewards have been updated`, { variant: "success" })
      setHasRewardsChanged(false)
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" })
    } finally {
      setIsRewardsLoading(false)
    }
  }, [hasRewardsChanged, updateRewards, lootboxRewards, enqueueSnackbar])

  const updateERC20Rewards = useCallback(
    async (
      tokenAddress: string,
      bundleTokens: string[],
      bundleBundles: string[]
    ): Promise<boolean | undefined> => {
      if (!lootboxToManage || !address) return

      const erc20RewardInInventory = erc20LootboxRewards.find(
        (erc20LootboxReward) =>
          erc20LootboxReward.tokenAddress.toLowerCase() === tokenAddress.toLowerCase()
      )

      if (!erc20RewardInInventory) return

      if (bundleBundles.some((bundle) => bundle.includes("."))) {
        enqueueSnackbar(t`Number of bundles must be whole numbers`, { variant: "error" })
        return
      }

      const tokens = bundleTokens.map((token) => parseFloat(token))
      const bundles = bundleBundles.map((bundle) => parseInt(bundle))

      if (!tokens.length || !bundles.length || bundles.length !== tokens.length) {
        enqueueSnackbar(t`Number of tokens and bundles must be valid`, { variant: "error" })
        return
      }
      if (tokens.some((token) => !token || Number.isNaN(token) || !(token > 0))) {
        enqueueSnackbar(t`Number of tokens must be valid`, { variant: "error" })
        return
      }
      if (bundles.some((bundle) => !bundle || Number.isNaN(bundle) || !(bundle > 1))) {
        enqueueSnackbar(t`Number of bundle must be valid and greater than 1`, { variant: "error" })
        return
      }
      if (new Set(tokens).size !== tokens.length) {
        enqueueSnackbar(t`Number of tokens must be unique for each row`, { variant: "error" })
        return
      }

      try {
        // process new bundles to mint from
        // increase in bundle number for old tokens
        // new bundles of tokens added
        const { newTokens, newBundles } = tokens.reduce(
          (acc, token, index) => {
            const foundBundle = erc20RewardInInventory.bundles.find(
              (bundle) => parseFloat(bundle.numberOfTokens) === token
            )

            if (foundBundle) {
              // There was an increase in bundle number
              const newBundleNumber = bundles[index] - parseInt(foundBundle.numberOfBundles)
              if (newBundleNumber < 0) {
                throw new Error(t`Number of bundles cannot be less than before`)
              } else if (newBundleNumber !== 0) {
                // If increase is 0, no need to mint for that token number
                acc.newTokens.push(token)
                acc.newBundles.push(newBundleNumber)
              }
            } else {
              // New bundles of tokens added
              acc.newTokens.push(token)
              acc.newBundles.push(bundles[index])
            }

            return acc
          },
          { newTokens: [] as number[], newBundles: [] as number[] }
        )

        await updateERC20Distributions(
          erc20RewardInInventory.tokenAddress,
          erc20RewardInInventory.wrapperAddress,
          newTokens,
          newBundles
        )

        enqueueSnackbar(t`ERC20 distributions updated`, { variant: "success" })
        return true
      } catch (error: any) {
        enqueueSnackbar(error?.message, { variant: "error" })
      }
    },
    [address, enqueueSnackbar, erc20LootboxRewards, lootboxToManage, updateERC20Distributions]
  )

  return {
    network,
    isRewardsLoading,
    isRewardsProcessing,
    setIsRewardsLoading,
    updateLootboxRewards,
    lootboxRewards,
    setLootboxRewards,
    hasRewardsChanged,
    setHasRewardsChanged,
    isVarERC20ModalOpen,
    setIsVarERC20ModalOpen,
    updateERC20Rewards,
    erc20LootboxRewards,
  }
}
