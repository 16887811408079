import type { SxProps, Theme } from "@mui/material"
import { createTheme } from "@mui/material"
import type { CSSProperties } from "react"

export const DRAWER_WIDTH = 200
export const BANNER_HEIGHT = 280

export type StylingEntry = SxProps<Theme> | CSSProperties

export interface IStylingCollection {
  [componentName: string]: StylingEntry
}

// define theme
export const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#B4FF68",
    },
    error: {
      main: "#FF7875",
    },
  },
  typography: {
    allVariants: {
      color: "#F5F5F5",
      fontFamily: "'NeueMontreal','Archivo', sans-serif",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#AFAFAF",
        },
        asterisk: {
          color: "#EF4444",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontWeight: 500,
          backgroundColor: "#B4FF68",
          borderRadius: 10,
          color: "black",
          ":hover": {
            backgroundColor: "#B4FF68",
          },
        },
        outlined: {
          borderRadius: 10,
          fontWeight: 500,
        },
        text: {
          borderRadius: 10,
          fontWeight: 500,
          backgroundColor: "transparent",
          ":hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "12px !important",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #000 inset",
            WebkitTextFillColor: "#fff",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          "input:-webkit-autofill,\ninput:-webkit-autofill:focus": {
            transition: "background-color 600000s 0s, color 600000s 0s",
          },
          "input[data-autocompleted]": { backgroundColor: "transparent !important" },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ":-ms-input-placeholder": {
            color: "gray",
          },
          ["& fieldset"]: {
            borderRadius: 12,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
          backgroundColor: "#BFBFBF",
          color: "#000000",
          padding: 8,
          textAlign: "center",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "12px",
          background: "#141414",
        },
      },
    },
  },
})
