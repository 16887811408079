import type { SvgIconProps } from "@mui/material"
import { SvgIcon } from "@mui/material"

export function CircleFilledIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5 12.4858C4.5 8.06984 8.084 4.48584 12.5 4.48584C16.916 4.48584 20.5 8.06984 20.5 12.4858C20.5 16.9018 16.916 20.4858 12.5 20.4858C8.084 20.4858 4.5 16.9018 4.5 12.4858ZM12.5 17.2858C15.148 17.2858 17.3 15.1338 17.3 12.4858C17.3 9.83784 15.148 7.68584 12.5 7.68584C9.852 7.68584 7.7 9.83784 7.7 12.4858C7.7 15.1338 9.852 17.2858 12.5 17.2858Z"
          fill="#588E23"
        />
      </svg>
    </SvgIcon>
  )
}
