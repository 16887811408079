import { trimChar } from "./utils/PathUtils"

export const BLOCKNATIVE_ID = process.env.REACT_APP_BLOCKNATIVE_ID
export const GAMING_API =
  trimChar(process.env.REACT_APP_GAMING_API || "", "/") || "https://game-api-stg.chainsafe.io"
export const STORAGE_API =
  trimChar(process.env.REACT_APP_STORAGE_API || "", "/") || "https://stage-api.chainsafe.io/api/v1"
export const IPFS_GATEWAY =
  trimChar(process.env.REACT_APP_IPFS_GATEWAY || "", "/") || "https://stage-ipfs.chainsafe.io"
export const INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID || ""
export const WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || ""
export const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === "true"
export const MAINTENANCE_WINDOW_TIMESTAMP = process.env.REACT_APP_MAINTENANCE_WINDOW_TIMESTAMP
  ? Number(process.env.REACT_APP_MAINTENANCE_WINDOW_TIMESTAMP)
  : undefined
export const INFURA_API_KEY = process.env.REACT_APP_INFURA_API_KEY
export const COUNTLY_APP_KEY = process.env.REACT_APP_COUNTLY_APP_KEY

// static consts for app
export const COUNTLY_APP_URL = "https://chainsafe-40aca7b26551e.flex.countly.com/"
