import { Trans } from "@lingui/macro"
import { Box, Button, Typography } from "@mui/material"
import React, { useMemo } from "react"

import { useLootbox } from "../../../../contexts/LootboxContext"
import { useWeb3Connection } from "../../../../contexts/Web3ConnectionContext"

interface IProps {
  openPriceToView: string
  lootboxType: number
  isOpeningLootbox: boolean
  openingLootboxMessage: string
  onOpenLootbox: () => void
}

const OpenLootboxView: React.FC<IProps> = ({
  lootboxType,
  openPriceToView,
  isOpeningLootbox,
  openingLootboxMessage,
  onOpenLootbox,
}) => {
  const { network } = useWeb3Connection()
  const { lootboxes, lootboxToOpen } = useLootbox()

  const lootbox = useMemo(
    () => lootboxes?.find((l) => l.address === lootboxToOpen),
    [lootboxToOpen, lootboxes]
  )

  return (
    <Box display="flex" mt={2} justifyContent="center">
      <Box
        sx={{
          width: "1000px",
          border: "1px solid #595959",
          borderRadius: "5px",
          p: 4,
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h4" sx={{ marginBottom: 1 }}>
              {lootbox?.name || "LootBox"}
            </Typography>
            <Typography>LootBox address: {lootboxToOpen}</Typography>
            <Typography>Rewards: {lootboxType}</Typography>
          </Box>
          <Typography variant="h4">
            {openPriceToView} {network?.token || ""}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" p={6}>
          <img src="/illustrations/lootbox-open-initial.png" alt="open LootBox" />
        </Box>
        {isOpeningLootbox ? (
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Typography fontSize={24} sx={{ mb: 1 }}>
              <Trans>Opening LootBox ...</Trans>
            </Typography>
            <Typography fontSize={18} sx={{ maxWidth: 380 }} align="center">
              <Trans>{openingLootboxMessage || "This may take some time to confirm"}</Trans>
            </Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center">
            <Button variant="contained" onClick={onOpenLootbox}>
              <Trans>Open LootBox</Trans>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default OpenLootboxView
