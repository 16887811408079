import { t } from "@lingui/macro"
import { useSetChain } from "@web3-onboard/react"
import { ethers } from "ethers"
import { useSnackbar } from "notistack"
import React, { useCallback, useState } from "react"

import MarketplaceAbi from "../contracts/Marketplace/Marketplace.json"
import MarketplaceFactoryAbi from "../contracts/Marketplace/MarketplaceFactory.json"
import Minter1155 from "../contracts/NFTLaunchPad/Minter1155.json"
import Minter721 from "../contracts/NFTLaunchPad/Minter721.json"
import { delay } from "../utils/Helpers"
import type { Network } from "../utils/NetworkHelpers"

import { useCountly } from "./CountlyContext"
import { useGamingApi } from "./GamingApiContext"
import type { NFTTokenType } from "./NFTsContext"
import type { IProject } from "./ProjectContext"
import { useWeb3Connection } from "./Web3ConnectionContext"

export type Marketplace = {
  id: string
  name: string
  chain_id: number
  project_id: string
  owner: string
  description?: string
  banner?: string
  contract_address: string
}

type CreateMarketplace = {
  name: string
  description?: string
  chain_id: number
  banner?: File
}

export type MarketplaceItem = {
  buyer: string
  deadline: number
  chain_id: number
  id: string
  listed_at: number
  marketplace_contract_address: string
  marketplace_id: string
  price: string
  project_id: string
  seller: string
  status: "listed"
  token: {
    contract_address: string
    metadata?: {
      image: string
      name: string
      tokenType?: NFTTokenType
    }
    uri: string
    token_id: string
    token_type: "ERC1155" | "ERC721"
  }
}

export type MarketplaceItemListings = MarketplaceItem & {
  listings?: {
    owner: string
    quantity: number
    price: string
  }[]
  minimumPriceListing?: string
}

const INITIAL_MARKETPLACE_OFFSET = 0
const INITIAL_MARKETPLACE_PAGE_SIZE = 100

const apiMarketplaceRoutes = {
  getMarketplaces: (projectId: string, offset?: number, pageSize?: number) =>
    `/v1/projects/${projectId}/marketplaces?${offset !== undefined ? `?offset=${offset}` : ""}${
      pageSize !== undefined ? `&pageSize=${pageSize}` : ""
    }`,
  getMarketplace: (projectId: string, marketplaceId: string) =>
    `/v1/projects/${projectId}/marketplaces/${marketplaceId}`,
  createMarketplace: (projectId: string) => `/v1/projects/${projectId}/marketplaces`,
  deleteMarketplace: (projectId: string, marketplaceId: string) =>
    `/v1/projects/${projectId}/marketplaces/${marketplaceId}`,
  syncMarketplace: (projectId: string, marketplaceId: string) =>
    `/v1/projects/${projectId}/marketplaces/${marketplaceId}/sync`,
  getMarketplaceItems: (projectId: string, marketplaceId: string) =>
    `/v1/projects/${projectId}/marketplaces/${marketplaceId}/items`,
  getOwnerNFTTokens: (projectId: string, address: string, chainId: string) =>
    `/v1/projects/${projectId}/tokens?account=${address}&chainID=${chainId}`,
}

export type MarketplaceContextType = {
  marketplaces?: Marketplace[]
  isLoadingMarketplaces: boolean
  hasLoadedMarketplaces: boolean
  marketplaceProject?: IProject
  getMarketplaces: () => void
  getMarketplaceFactoryContract: (chainId: number) => ethers.Contract | undefined
  marketplaceNetworks: Network[]
  marketplace?: Marketplace
  hasLoadedMarketplace: boolean
  getMarketplace: (marketplaceId: string) => Promise<void>
  setMarketplaceProject: (marketplaceProject: IProject | undefined) => void
  createMarketplace: (data: CreateMarketplace) => Promise<Marketplace | undefined>
  deleteMarketplace: (projectId: string, marketplaceId: string) => Promise<void>
  createMarketplaceOnChain: (marketplace: Marketplace) => Promise<void>
  syncOnCreateMarketplace: (marketplace: Marketplace) => Promise<boolean | undefined>
  getMarketplaceItems: (marketplaceId: string) => Promise<MarketplaceItemListings[]>
  getOwnerNFTTokens: () => Promise<NFTTokenType[]>
  listItem: (NFTItem: NFTTokenType, price: string, amount?: string) => Promise<void>
}

type MarketplaceContextProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

const MarketplaceContext = React.createContext<MarketplaceContextType | undefined>(undefined)

const MarketplaceProvider = ({ children }: MarketplaceContextProviderProps): JSX.Element => {
  const { gamingApiInstance } = useGamingApi()
  const { address, network, provider, networks } = useWeb3Connection()
  const [, setChain] = useSetChain()
  const { trackEvent } = useCountly()
  const { enqueueSnackbar } = useSnackbar()

  const [marketplaces, setMarketplaces] = useState<Marketplace[]>([])
  const [isLoadingMarketplaces, setIsLoadingMarketplaces] = useState(false)
  const [hasLoadedMarketplaces, setHasLoadedMarketplaces] = useState(false)
  const [marketplaceProject, setMarketplaceProject] = useState<IProject>()
  const [marketplace, setMarketplace] = useState<Marketplace>()
  const [hasLoadedMarketplace, setHasLoadedMarketplace] = useState(false)

  const marketplaceNetworks = networks.filter(
    (n) => !n.isLocalNetwork && !!n.marketplaceFactoryAddress
  )

  const getMarketplaceFactoryContract = useCallback(
    (chainId: number) => {
      // getting the contract ready
      const contractAddress = marketplaceNetworks.find(
        (marketplaceNetwork) => marketplaceNetwork.chainId === chainId
      )?.marketplaceFactoryAddress

      if (!contractAddress || !provider) return

      const signer = provider?.getSigner(0)
      return new ethers.Contract(
        contractAddress, // contract address
        MarketplaceFactoryAbi.abi, // contract abi (meta-data)
        signer // Signer object signs and sends transactions
      )
    },
    [marketplaceNetworks, provider]
  )

  const getMarketplaceContract = useCallback(() => {
    if (!marketplace) return
    // getting the contract ready
    const contractAddress = marketplace.contract_address

    if (!contractAddress || !provider) return

    const signer = provider?.getSigner(0)
    return new ethers.Contract(
      contractAddress, // contract address
      MarketplaceAbi.abi, // contract abi (meta-data)
      signer // Signer object signs and sends transactions
    )
  }, [marketplace, provider])

  const getMarketplaces = useCallback(() => {
    if (!marketplaceProject || !gamingApiInstance) return
    setIsLoadingMarketplaces(true)
    gamingApiInstance
      .get(
        apiMarketplaceRoutes.getMarketplaces(
          marketplaceProject.projectId,
          INITIAL_MARKETPLACE_OFFSET,
          INITIAL_MARKETPLACE_PAGE_SIZE
        )
      )
      .then(({ data }) => {
        if (data && JSON.parse(data)) {
          setMarketplaces(JSON.parse(data).marketplaces)
        }
        setHasLoadedMarketplaces(true)
        setIsLoadingMarketplaces(false)
      })
      .catch((e) => {
        console.error(e)
        setMarketplaces([])
        setHasLoadedMarketplaces(true)
        setIsLoadingMarketplaces(false)
      })
  }, [marketplaceProject, gamingApiInstance])

  const createMarketplace = useCallback(
    async (data: CreateMarketplace) => {
      try {
        if (!gamingApiInstance || !marketplaceProject) return

        // create form data for API
        const formData = new FormData()

        formData.append("name", data.name)
        formData.append("chain_id", data.chain_id.toString())

        if (data.description) formData.append("description", data.description)
        if (data.banner) formData.append("banner", data.banner)

        const resp = await gamingApiInstance.post(
          apiMarketplaceRoutes.createMarketplace(marketplaceProject.projectId),
          formData
        )

        const newMarketplace: Marketplace = JSON.parse(resp.data)

        // track event
        trackEvent({
          key: "marketplace-created",
          segmentation: {
            project_id: marketplaceProject.projectId,
            marketplace_id: newMarketplace.id,
          },
        })

        return newMarketplace
      } catch (error) {
        enqueueSnackbar(t`Failed to create marketplace`, {
          variant: "error",
        })
      }
    },
    [marketplaceProject, enqueueSnackbar, gamingApiInstance, trackEvent]
  )

  const deleteMarketplace = useCallback(
    async (projectId: string, marketplaceId: string) => {
      try {
        if (!gamingApiInstance || !marketplaceProject) return

        await gamingApiInstance.delete(
          apiMarketplaceRoutes.deleteMarketplace(projectId, marketplaceId)
        )
        trackEvent({
          key: "marketplace-deleted",
          segmentation: { project_id: marketplaceProject.projectId, marketplace_id: marketplaceId },
        })
      } catch (error) {
        console.error(error)
        enqueueSnackbar(t`Failed to delete marketplace`, {
          variant: "error",
        })
      }
    },
    [marketplaceProject, enqueueSnackbar, gamingApiInstance, trackEvent]
  )

  const createMarketplaceOnChain = useCallback(
    async (marketplaceToCreate: Marketplace) => {
      // ensure correct chainId
      if (network?.chainId !== marketplaceToCreate.chain_id) {
        const wasNetworkSet = await setChain({
          chainId: `0x${marketplaceToCreate.chain_id.toString(16)}`,
        })
        if (!wasNetworkSet) {
          enqueueSnackbar("Failed to switch network", { variant: "error" })
          return
        }
      }
      const marketplaceFactory = getMarketplaceFactoryContract(marketplaceToCreate.chain_id)
      if (!marketplaceFactory) return
      // creating on chain marketplace
      const marketplaceTx = await marketplaceFactory.createMarketplace(
        marketplaceProject?.projectId,
        marketplaceToCreate.id,
        false
      )
      await marketplaceTx
      trackEvent({
        key: "marketplace-created-on-chain",
        segmentation: {
          project_id: marketplaceProject?.projectId,
          marketplace_id: marketplaceToCreate.id,
        },
      })
    },
    [
      marketplaceProject,
      enqueueSnackbar,
      getMarketplaceFactoryContract,
      network,
      setChain,
      trackEvent,
    ]
  )

  const syncOnCreateMarketplace = useCallback(
    async (marketplaceToCreate: Marketplace) => {
      if (!gamingApiInstance || !marketplaceProject) return

      try {
        await gamingApiInstance.post(
          apiMarketplaceRoutes.syncMarketplace(marketplaceProject.projectId, marketplaceToCreate.id)
        )
        return true
      } catch {
        return false
      }
    },
    [marketplaceProject, gamingApiInstance]
  )

  const getMarketplace = useCallback(
    async (marketplaceId: string) => {
      try {
        if (!gamingApiInstance || !marketplaceProject) return

        const { data } = await gamingApiInstance.get(
          apiMarketplaceRoutes.getMarketplace(marketplaceProject.projectId, marketplaceId)
        )
        setMarketplace(JSON.parse(data))
        setHasLoadedMarketplace(true)
      } catch (error) {
        setHasLoadedMarketplace(true)
        console.error(error)
      }
    },
    [marketplaceProject, gamingApiInstance]
  )

  const getMarketplaceItems = useCallback(
    async (marketplaceId: string) => {
      if (!marketplaceProject || !marketplace || !gamingApiInstance) return []
      const combinedListingsNFTs: MarketplaceItemListings[] = []
      try {
        const { data } = await gamingApiInstance.get(
          apiMarketplaceRoutes.getMarketplaceItems(marketplaceProject.projectId, marketplaceId)
        )
        if (data && JSON.parse(data)) {
          const NFTs = JSON.parse(data).items as MarketplaceItem[]

          NFTs.forEach((NFTItem) => {
            // pushes 721 right into array
            if (NFTItem.token.token_type === "ERC721") {
              combinedListingsNFTs.push({
                ...NFTItem,
                listings: [
                  {
                    owner: NFTItem.seller,
                    quantity: 1,
                    price: NFTItem.price,
                  },
                ],
                minimumPriceListing: NFTItem.price,
              })

              return
            }
            // checks if there is already the same address and id 1155 token in array
            const stored1155 = combinedListingsNFTs.find(
              (combinedNFT) =>
                combinedNFT.token.contract_address === NFTItem.token.contract_address &&
                combinedNFT.token.token_id === NFTItem.token.token_id
            )
            // first unique 1155
            if (stored1155 === undefined) {
              combinedListingsNFTs.push({
                ...NFTItem,
                listings: [
                  {
                    owner: NFTItem.seller,
                    quantity: 1,
                    price: NFTItem.price,
                  },
                ],
                minimumPriceListing: NFTItem.price,
              })
              // same id and address 1155
            } else {
              // check if there are same token with same price
              const samePriceListingIndex = stored1155.listings!.findIndex(
                (listing) => listing.price === NFTItem.price
              )
              // if there is not push to listings
              if (samePriceListingIndex === -1) {
                stored1155.listings!.push({
                  owner: NFTItem.seller,
                  quantity: 1,
                  price: NFTItem.price,
                })
                if (Number(stored1155.minimumPriceListing) > Number(NFTItem.price)) {
                  stored1155.minimumPriceListing = NFTItem.price
                }
                // combine same price same token listings
              } else {
                stored1155.listings![samePriceListingIndex].quantity++
              }
            }
          })
          return combinedListingsNFTs
        }
      } catch (err) {
        console.error(err)
      }
      return []
    },
    [marketplaceProject, marketplace, gamingApiInstance]
  )

  const getOwnerNFTTokens = useCallback(async () => {
    if (!marketplaceProject || !address || !network || !gamingApiInstance) return
    try {
      const { data } = await gamingApiInstance.get(
        apiMarketplaceRoutes.getOwnerNFTTokens(
          marketplaceProject.projectId,
          address,
          network.chainId.toString()
        )
      )
      if (data && JSON.parse(data)) {
        return JSON.parse(data).tokens
      }
      return []
    } catch (err) {
      console.error(err)
    }
  }, [marketplaceProject, address, network, gamingApiInstance])

  const listItem = useCallback(
    async (NFTItem: NFTTokenType, price: string, amount = "1") => {
      if (!marketplace || !provider || !address) return
      // ensure correct chainId
      if (network?.chainId !== marketplace.chain_id) {
        const wasNetworkSet = await setChain({ chainId: `0x${marketplace.chain_id.toString(16)}` })
        if (!wasNetworkSet) {
          enqueueSnackbar("Failed to switch network", { variant: "error" })
          return
        }
      }

      const signer = provider.getSigner(0)

      // check approval for marketplace
      const NFTContract = new ethers.Contract(
        NFTItem.contract_address, // contract address
        NFTItem.token_type === "ERC721" ? Minter721.abi : Minter1155.abi, // contract abi (meta-data)
        signer // Signer object signs and sends transactions
      )

      const isApprovedInitial = await NFTContract.isApprovedForAll(
        address,
        marketplace.contract_address
      )

      // marketplace not approved, approve and wait
      if (!isApprovedInitial) {
        const approvalTx = await NFTContract.setApprovalForAll(marketplace.contract_address, true)
        await approvalTx

        let isApproved = false
        do {
          await delay(3000)
          isApproved = await NFTContract.isApprovedForAll(address, marketplace.contract_address)
        } while (!isApproved)
      }

      const marketplaceContract = getMarketplaceContract()
      if (!marketplaceContract) return
      // creating on chain marketplace
      const listItemTx = await marketplaceContract.listItems(
        [NFTItem.contract_address],
        [NFTItem.token_id],
        [amount],
        [ethers.utils.parseUnits(price, "ether")],
        [0]
      )
      await listItemTx
      trackEvent({
        key: "marketplace-nft-item-listed",
        segmentation: {
          project_id: marketplaceProject?.projectId,
          marketplace_id: marketplace.id,
          nft_address: NFTItem.contract_address,
        },
      })
    },
    [
      marketplace,
      enqueueSnackbar,
      getMarketplaceContract,
      setChain,
      network,
      trackEvent,
      provider,
      address,
      marketplaceProject,
    ]
  )

  return (
    <MarketplaceContext.Provider
      value={{
        marketplaces,
        isLoadingMarketplaces,
        hasLoadedMarketplaces,
        marketplaceProject,
        marketplaceNetworks,
        getMarketplaceFactoryContract,
        setMarketplaceProject,
        getMarketplaces,
        marketplace,
        getMarketplace,
        hasLoadedMarketplace,
        createMarketplace,
        deleteMarketplace,
        createMarketplaceOnChain,
        syncOnCreateMarketplace,
        getMarketplaceItems,
        getOwnerNFTTokens,
        listItem,
      }}
    >
      {children}
    </MarketplaceContext.Provider>
  )
}

function useMarketplace(): MarketplaceContextType {
  const context = React.useContext(MarketplaceContext)
  if (context === undefined) {
    throw new Error("useMarketplace must be used within a MarketplaceContext")
  }
  return context
}

export { MarketplaceProvider, useMarketplace }
