import { Trans } from "@lingui/macro"
import React from "react"

import { CircleBlankIcon } from "../../../../assets/icons/CircleBlankIcon"
import { CircleFilledIcon } from "../../../../assets/icons/CircleFilledIcon"
import { useProfileInfo } from "../../../../contexts/ProfileInfoContext"

import {
  SubscriptionsContainer,
  SubscriptionsFooter,
  SubscriptionsFooterText,
  SubscriptionsLayout,
  SubscriptionsRow,
  SubscriptionText,
} from "./SubscriptionsView.style"

const SubscriptionsView: React.FC = () => {
  const { subscriptions } = useProfileInfo()

  return (
    <SubscriptionsLayout>
      <SubscriptionsContainer>
        <SubscriptionsRow>
          {subscriptions?.productCommunications ? (
            <CircleFilledIcon data-cy="icon-product-communications-checkbox-checked" />
          ) : (
            <CircleBlankIcon data-cy="icon-product-communications-checkbox-unchecked" />
          )}
          <SubscriptionText>
            <Trans>Communications from our Product team</Trans>
          </SubscriptionText>
        </SubscriptionsRow>

        <SubscriptionsRow>
          {subscriptions?.newsletters ? (
            <CircleFilledIcon data-cy="icon-newsletters-checkbox-checked" />
          ) : (
            <CircleBlankIcon data-cy="icon-newsletters-checkbox-unchecked" />
          )}
          <SubscriptionText>
            <Trans>Monthly Gaming community newsletter</Trans>
          </SubscriptionText>
        </SubscriptionsRow>
      </SubscriptionsContainer>

      <SubscriptionsFooter>
        <SubscriptionsFooterText variant="body2">
          <Trans>
            *To unsubscribe from a mailing list, click the ‘unsubscribe’ button at the bottom of the
            email you received.
          </Trans>
        </SubscriptionsFooterText>
      </SubscriptionsFooter>
    </SubscriptionsLayout>
  )
}

export default SubscriptionsView
