import { Box, styled, Typography } from "@mui/material"

export const SubscriptionsLayout = styled(Box)`
  padding: 16px;
`

export const SubscriptionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const SubscriptionsRow = styled(Box)`
  padding-left: 16px;
  display: flex;
  align-items: center;
`

export const SubscriptionText = styled(Typography)`
  margin-left: 4px;
`

export const SubscriptionsFooter = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
`

export const SubscriptionsFooterText = styled(Typography)`
  font-style: italic;
  color: #d9d9d9;
  text-align: left;
`
